import { Button, Card, Popconfirm, Table, Tag, Tooltip } from "antd";
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { SecureWithLogin, useOidcUser } from "../../../../utils/secure";
import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import LoadingSkeleton from "../../../Layout/LoadingSkeleton";
import StatusPills from '../../../crm/common/StatusPills';
import { callBackEnd } from "../../../../utils/backEndUtils";
import { getPackageTypes } from '../../../../utils/getPackageTypes';
import useAllEnvironments from "../../../../hooks/useAllEnvironments";

type NSAUserPackagesAssignProps = {
    readonly user: any,
    readonly namespaceId?: string
}

export default function NSAUserPackagesAssign(props: NSAUserPackagesAssignProps) {
    const { user, namespaceId } = props;
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [userPackages, setUserPackages] = useState<any[]>([]);
    const [packages, setPackages] = useState<any[]>([]);
    const { allEnv } = useAllEnvironments();
    const [refreshCounter, setRefreshCounter] = useState<number>(0);

    const { oidcUser }: { oidcUser: any } = useOidcUser(); //logged user
    const isRestricted: boolean = oidcUser?.jetUserId === user?.uid; //user can't edit himself

    useEffect(() => {
        async function getUserPackages() {
            try {
                setIsProcessing(true);
                const response: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}/users/${user?.uid}/packages`);
                setUserPackages(response);
            } catch (error: any) {
                throw new Error(error);
            } finally {
                setIsProcessing(false);
            }
        }
        async function getPackages() {
            try {
                setIsProcessing(true);
                const response: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}/packages`);
                setPackages(response);
                console.log("🚀 ~ getPackages ~ response:", response);
            } catch (error: any) {
                throw new Error(error);
            } finally {
                setIsProcessing(false);
            }
        }
        if (namespaceId && user) {
            getUserPackages().catch((e: any) => console.log(e));
            getPackages().catch((e: any) => console.log(e));
        }
    }, [namespaceId, user, refreshCounter]);

    const handleRemovePackage = async (packageId: string) => {
        ///
        try {
            setIsProcessing(true);
            await callBackEnd('portal', 'delete', `namespaces/${namespaceId}/packages/${packageId}/users/${user.uid}`);
            console.log(`namespaces/${namespaceId}/packages/${packageId}/users/${user.uid}`);

        } catch (error: any) {
            throw new Error(error);
        } finally {
            setRefreshCounter(refreshCounter + 1);
        }
    }
    const handleAssignPackage = async (packageId: string) => {
        ///
        try {
            setIsProcessing(true);
            await callBackEnd('portal', 'POST', `namespaces/${namespaceId}/packages/${packageId}/users`, JSON.stringify({ uid: user.uid }));

        } catch (error: any) {
            throw new Error(error);
        } finally {
            setRefreshCounter(refreshCounter + 1);
        }
    }

    return (
        <SecureWithLogin callbackPath="/nsadmin" role={['nsadmin_users']}>
            {!isProcessing && userPackages.length > 0 && packages.length > 0 ?

                <Card
                    title="Packages"
                    type="inner"
                    extra={
                        <Link to="/nsadmin/packages">
                            <Button type="primary">View all packages</Button>
                        </Link>
                    }
                >
                    <Table
                        dataSource={packages}
                        rowKey="uid"
                        pagination={false}
                        columns={[
                            {
                                title: 'Assigned',
                                dataIndex: 'assigned',
                                key: 'assigned',
                                render: (status: string, pkg: Package) => {
                                    const isAssigned: boolean = userPackages.find((up: any) => up.uid === pkg.uid);
                                    return (
                                        <StatusPills type={isAssigned ? "enabled" : "disabled"} />
                                    )

                                },
                                filters: [
                                    { text: 'Enabled', value: "enabled" },
                                    { text: 'Disabled', value: "disabled" }
                                ],
                                onFilter: (value: any, p: any) => p.status.toLowerCase() === value,
                            },
                            {
                                title: 'Name',
                                dataIndex: 'name',
                                key: 'name',
                                render: (name: string, record: Package) => {
                                    return (
                                        <Link to={`/nsadmin/packages/`}>

                                            <Tooltip title="Go to Package details">
                                                {name}
                                            </Tooltip>

                                        </Link>
                                    )
                                }
                            },
                            {
                                title: 'Environment',
                                dataIndex: 'environment',
                                key: 'environment',
                                filters: allEnv.map((e: any) => {
                                    return { text: e.label, value: e.name }
                                }),
                                onFilter: (value: any, p: any) => p.environment === value,
                                render: (env: string) => {
                                    const color: string = allEnv.find((e: any) => e.name === env)?.color;
                                    return <Tag color={color}>{env}</Tag>
                                }
                            },
                            {
                                title: 'Type',
                                dataIndex: 'type',
                                key: 'type',
                                render: (type: string) => {
                                    return <Tag color={"blue"}>{type.replace(/_/g, ' ')}</Tag>
                                },
                                filters: getPackageTypes().map((t: any) => {
                                    return { text: t.replace(/_/g, ' '), value: t }
                                }
                                ),
                                onFilter: (value: any, p: any) => p.type === value,
                            },
                            {
                                title: 'Actions',
                                key: 'actions',
                                render: (text: string, pkg: any) => {
                                    const isAssigned: boolean = userPackages.find((up: any) => up.uid === pkg.uid);
                                    return (
                                        <>
                                            {isAssigned ?
                                                <Popconfirm
                                                    title="Remove Package"
                                                    description="Are you sure to remove this package?"
                                                    onConfirm={() => { handleRemovePackage(pkg.uid) }}
                                                    onCancel={() => { }}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Tooltip title={isRestricted && "You can not edit your own account"}>
                                                        <Button type="default" danger icon={<CloseCircleOutlined />} disabled={isRestricted}>Remove Package</Button>
                                                    </Tooltip>
                                                </Popconfirm>
                                                :
                                                <Popconfirm
                                                    title="Assign Package"
                                                    description="Are you sure to assign this package?"
                                                    onConfirm={() => { handleAssignPackage(pkg.uid) }}
                                                    onCancel={() => { }}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Tooltip title={isRestricted && "You can not edit your own account"}>
                                                        <Button type="primary" icon={<PlusCircleOutlined />} disabled={isRestricted}>Assign Package</Button>
                                                    </Tooltip>
                                                </Popconfirm>
                                            }
                                        </>
                                    )
                                }
                            }
                        ]}
                    />

                </Card>
                :
                <LoadingSkeleton />
            }


        </SecureWithLogin>
    )
}
