import '../../../assets/scss/commonModules/TechInfos.scss';

import { Descriptions } from 'antd';
import type { DescriptionsProps } from 'antd';

// import { useEffect, useState } from 'react';

// import { callBackEnd } from '../../../utils/backEndUtils'

export default function TechInfos(props: any) {

    const { techProps } = props;

    // const partnerId = techProps.find((tp: any) => tp.name === 'Partner')?.value;
    // const [partner, setPartner] = useState<Partner | null>(null);

    // useEffect(() => {

    //     async function getPartner() {
    //         try {
    //             const response: any = await callBackEnd('portal', 'get', `partners/${partnerId}`);
    //             setPartner(response);
    //         } catch (error: any) {
    //             console.log(error);
    //         }
    //     }

    //     if (partnerId && partnerId !== '') {
    //         getPartner().catch((e: any) => console.log(e));
    //     }
    // }, [partnerId])

    if (techProps.length === 0) {
        return null
    }

    const items: DescriptionsProps['items'] = techProps.map((tp: any, index: number) => {
        return {
            key: index.toString(),
            label: tp.name,
            children: <p>{tp.value}</p>
        }
    })


    return (
        
            <Descriptions title="Technical Informations" column={4} items={items} />
        
    )
}
