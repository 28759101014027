

export const getAvailableCollectionByProduct = (product: string, env: string, accessTokenPayload: any) => {
    
    //todo : check the package type
    
    const volumesOfEnv: any = accessTokenPayload?.volumes[env.toLowerCase()]

    let collections: string[] = [];
    if (volumesOfEnv?.hasOwnProperty(product.toLowerCase())) {
        collections = volumesOfEnv[product.toLowerCase()]?.map((v: any) => {
            if (v.collection !== undefined) {
                return v.collection;//.toLowerCase();
            }
            return null;
        });
    }
    const collectionSet = collections.length ? new Set(collections.flat()) : [];

    return [...collectionSet];
}