import { Button, Col, Flex, Row } from 'antd'

import ClusterStatusCollectionsStatus from './clusterStatusComponents/ClusterStatusCollectionsStatus'
import ClusterStatusIllustration from './clusterStatusComponents/ClusterStatusIllustration'
import ClusterStatusServiceStatus from './clusterStatusComponents/ClusterStatusServiceStatus'
import { Content } from 'antd/es/layout/layout'
import { EnvContext } from '../../contexts/EnvironmentContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HomeOutlined } from '@ant-design/icons'
import Loading from '../Layout/Loading'
import MotionBox from '../Layout/Animated/MotionBox'
import { PageHeader } from '../Layout/header/PageHeader'
import { SecureWithLogin } from '../../utils/secure'
import ShowError from '../Layout/ShowError'
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import useClusterStatus from '../../hooks/useClusterStatus'
import { useContext } from 'react'

const ClusterStatus = () => {

    const env: string = useContext(EnvContext);
    const { clusterStatus, loading, error } = useClusterStatus(env);
    return (
        <SecureWithLogin callbackPath="/clusterStatus" role={['portal_cluster_status']}>
            <PageHeader
                withEnvSelector
                title='Service Status'
                subtitle='Dashboard'
                breadcrumbs={[
                    { title: <span><HomeOutlined />&nbsp;Home</span>, href: '/', },
                    { title: <span>Cluster Status</span> }]}
            />

            <MotionBox>
                <Content>
                    {(!loading && error === null) ?
                        <>
                            {clusterStatus &&
                                <Row gutter={[24, 24]}>
                                    <Col xs={24} className='service-status-illustration'><ClusterStatusIllustration clusterStatus={clusterStatus} /></Col>
                                    <Col xs={24} className='service-status-clusters-status'><ClusterStatusServiceStatus clusterStatus={clusterStatus} /></Col>
                                    <Col xs={24} className='service-status-collections-status'><ClusterStatusCollectionsStatus clusterStatus={clusterStatus} env={env} /></Col>
                                    {env === 'PROD' &&
                                        <Col xs={24}>
                                            <Row gutter={[24, 24]} justify='center' align='middle'>
                                                <Col xs={24} lg={18} xxl={12} className='service-status-view-uptime'>
                                                    <Flex justify='end'><Button className="customSmallButton base" href="https://status.neterium.io/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faUpRightFromSquare}></FontAwesomeIcon>{' '}View on uptime.com</Button></Flex>
                                                </Col>
                                            </Row>
                                        </Col>
                                    }
                                </Row>
                            }
                        </>
                        :
                        <>
                            <Row gutter={[24, 24]}>
                                <Col xs={24} className='service-status-illustration'>
                                    {loading && <Loading />}
                                    {error && <><ShowError error={`Unable to get the status of the ${env.toLowerCase()} environment`} /></>}
                                </Col>
                            </Row>
                        </>
                    }
                </Content>
            </MotionBox>
        </SecureWithLogin>
    )
}

export default ClusterStatus