import { Badge, Flex } from 'antd';
import { useEffect, useState } from 'react';

import Loading from '../../Layout/Loading';
import ShowError from '../../Layout/ShowError';
import StatusTag from './StatusTag';
import { callBackEnd } from '../../../utils/backEndUtils';
import { getClusterStatus } from '../../../utils/clusterStatusUtils';

export default function CollectionStatus(props: Readonly<{ targetRealm: string, collection: string }>) {
    const { targetRealm, collection } = props;

    const [collectionStatus, setCollectionStatus] = useState<string>('red');
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        async function getCollectionStatus() {
            try {
                const response: any = await callBackEnd(targetRealm, 'get', `api/v1/core/cluster-status/${collection}`);
                setCollectionStatus(response.status);
            } catch (error: any) {
                console.log(error);
                setError(error);
            } finally {
                setLoading(false);
            }
        }
        getCollectionStatus().catch((error) => { console.log(error) });
    }, [targetRealm, collection])

    return (
        <>
            {!loading && error === null
                ?
                <Flex style={{width:'100%'}} justify='space-between'><Badge status={getClusterStatus(collectionStatus) as "error" | "success" | "processing" | "default" | "warning"} text={collection.toUpperCase()}/><StatusTag status={collectionStatus}/></Flex>
                :
                <>
                    {loading && <Loading />}
                    {error && <ShowError error={error} />}
                </>
            }
        </>
    )
}
