import { Button, Card, Form, Select, notification } from "antd";
import { SecureOwnEdits, SecureWithLogin } from "../../../utils/secure";
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";

import { Content } from "antd/es/layout/layout";
import CustomModal from "../common/CustomModal";
import MotionBox from "../../Layout/Animated/MotionBox";
import { callBackEnd } from "../../../utils/backEndUtils";
import { useForm } from "antd/es/form/Form";

export default function FormSetNamespace() {
    const { userId } = useParams();
    const [form] = useForm();
    const [loading, setLoading] = useState<boolean>(true);
    const [namespaces, setNamespaces] = useState<any[]>([]);
    const [user, setUser] = useState<any>(null);
    const [showChangeModal, setShowChangeModal] = useState<boolean>(false);
    const navigate = useNavigate();
    

    useEffect(() => {
        async function getNamespaces() {
            try {
                setLoading(true);
                const response: any = await callBackEnd('portal', 'get', `namespaces`);
                setNamespaces(response);
            } catch (error: any) {
                console.log(error);
            }
            finally {
                setLoading(false);
            }
        }
        async function getUserNamespaces() {
            try {
                setLoading(true);
                const response: any = await callBackEnd('portal', 'get', `users/${userId}`);
                if (response?.namespaceId) {
                    form.setFieldValue('namespace', response?.namespaceId);
                }
                setUser(response);
            } catch (error: any) {
                console.log(error);
            }
            finally {
                setLoading(false);
            }
        }


        getNamespaces().catch((e: any) => console.log(e));
        getUserNamespaces().catch((e: any) => console.log(e));
    }, [userId, form]);

    const formOptions: any = () => {
        if (namespaces.length === 0) return null;
        return namespaces.map((namespace: any) => {
            return { value: namespace.uid, label: namespace.name + ' - ' + namespace?.label }
        }
        )
    }

    const handleChangeModalResponse = (response: boolean) => {
        if (response) {
            setShowChangeModal(false);
            form.submit();
        }
        else {
            setShowChangeModal(false);
        }
    }

    const handleForm = async (values: any) => {
        try {
            setLoading(true);
            await callBackEnd('portal', 'put', `admin/users/${userId}/namespace/${values.namespace}`);
            navigate(`/users/${userId}`);

        } catch (error: any) {
            console.log(error);
        }
        finally {
            setLoading(false);
            notification.success({ message: 'Namespace assigned!' });
        }

    }

    return (
        <SecureWithLogin callbackPath="/users" role={['crm_users']}>
            {userId &&
                <SecureOwnEdits userId={userId}>
                    <MotionBox>
                        <Content>
                            <Card title="Assign Namespace">
                                {loading && <div>Loading...</div>}
                                {!loading && namespaces.length === 0 && <div>No namespaces found</div>}
                                {!loading && namespaces.length > 0 && user &&
                                    <Form
                                        labelWrap
                                        form={form}
                                        layout="vertical"
                                        name="basic"
                                        onFinish={handleForm}
                                    >
                                        <Form.Item
                                            name="namespace"
                                            rules={[{ required: true, message: 'Please select a Namespace!' }]}
                                        >
                                            <Select
                                                placeholder="Select a namespace"
                                                showSearch
                                                options={formOptions()}
                                                optionFilterProp="children"
                                                filterOption={(input: any, option: any) => (option?.label ?? '').includes(input)}
                                                filterSort={(optionA: any, optionB: any) =>
                                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                }
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button type="primary" onClick={(e) => setShowChangeModal(true)}>Assign user to namespace</Button>

                                        </Form.Item>
                                    </Form>
                                }
                            </Card>
                            {showChangeModal && user !== null &&
                                <CustomModal title="Assign Namespace to user" message={`Are you sure you want to assign this Namespace ?`} callback={handleChangeModalResponse} />
                            }
                        </Content>
                    </MotionBox>
                </SecureOwnEdits>
            }
        </SecureWithLogin >
    )
}
