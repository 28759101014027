import { Button, Card, Input, Space, Table, TableColumnsType, Tooltip } from 'antd';
import { EyeTwoTone, UserAddOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { faCheckCircle, faCircle, faCircleCheck, faUser, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import MotionBox from '../../Layout/Animated/MotionBox';
import { useState } from 'react';

export default function UsersListWithNamespace(props: Readonly<IUsersListWithNamespaceProps>) {
    const { users, title, namespaces } = props;

    const [search, setSearch] = useState<string>('');
    const navigate = useNavigate();

    const getNamespaceName = (uid: string | undefined) => {
        if (uid === undefined) return '';
        const namespace = namespaces.find((n: Namespace) => n.uid === uid);
        return namespace?.label;
    }

    const showBooleanIcons = (value: boolean) => {
        return (
            <span className={(value) ? 'userPill green' : 'userPill red'}>
                <FontAwesomeIcon icon={(value) ? faCheckCircle : faXmarkCircle} />
            </span>
        )
    }

    const showVerifiedStatus = (value: string) => {
        let icon: any = null;
        let classColor: string = '';
        switch (value) {
            case 'REGISTERED':
            case 'NAMESPACE_JOIN_REQUESTED':
                icon = faCircle;
                classColor = 'orange';
                break;
            case 'VERIFIED':
                icon = faCircleCheck;
                classColor = 'green';
                break;
            case 'REJECTED':
                classColor = 'red';
                icon = faXmarkCircle;
                break;
        }
        return (
            <span className={`${classColor} userPill`}>
                <FontAwesomeIcon icon={icon} />
            </span>
        )
    }
    const getDistinctCompanyNames = () => {
        const companyNames: string[] = [];
        users.forEach((u: UserProfile) => {
            if (u.company && !companyNames.includes(u.company)) companyNames.push(u.company);
        });
        return companyNames;
    }
    const getDistinctNamespaces = () => {
        const namespaceNames: string[] = [];
        users.forEach((u: UserProfile) => {
            if (u.namespaceId && !namespaceNames.includes(u.namespaceId)) namespaceNames.push(u.namespaceId);
        });
        return namespaceNames;
    }
    const columns: TableColumnsType<UserProfile> = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: (a: UserProfile, b: UserProfile) => a.firstName.localeCompare(b.firstName),
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            sorter: (a: UserProfile, b: UserProfile) => a.lastName.localeCompare(b.lastName),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: (a: UserProfile, b: UserProfile) => a.email.localeCompare(b.email),
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
            filters: getDistinctCompanyNames().map((c: string) => ({ text: c, value: c })),
            onFilter: (value: any, u: any) => u.company === value,
        },
        {
            title: 'Namespace',
            dataIndex: 'namespaceId',
            key: 'namespaceId',
            render: (v: any, u: any) => u?.namespaceId ? getNamespaceName(u?.namespaceId): 'N/A',
            hidden: (namespaces.length === 0),
            filters: [
                ...getDistinctNamespaces().map((n: string) => ({ text: getNamespaceName(n), value: n })),
                { text: 'Not in a Namespace', value: 'no_namespace' }, // Add this line
            ],
            onFilter: (value: any, record: any) => {
                if (value === 'no_namespace') {
                    return !record.namespaceId;
                }
                return record.namespaceId === value;
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            filters: [
                { text: 'Registered', value: "REGISTERED" },
                { text: 'Namespace Join Requested', value: "NAMESPACE_JOIN_REQUESTED" },
                { text: 'Verified', value: "VERIFIED" },
                { text: 'Rejected', value: "REJECTED" },
            ],
            defaultFilteredValue: (users.filter((u: UserProfile) => u.status === 'REGISTERED').length > 0) ? ['REGISTERED'] : ['VERIFIED', 'REJECTED', 'REGISTERED', 'NAMESPACE_JOIN_REQUESTED'],
            onFilter: (value: any, u: any) => u.status === value,
            render: (value: string) => <>{showVerifiedStatus(value)} {' '}{value}</>,
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            render: (value: boolean) => showBooleanIcons(value),
            filters: [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
            ],
            onFilter: (value: any, u: any) => u.enabled === value,
            sorter: (a: UserProfile, b: UserProfile) => a.enabled.toString().localeCompare(b.enabled.toString()),
        },

        {
            title: 'Last Login',
            dataIndex: 'lastLogin',
            key: 'lastLogin',
            render: (value: Date) => <Moment date={value} format="YYYY-MM-DD hh:mm:ss" />
        },

    ];
    columns.push({
        title: 'Actions',
        dataIndex: 'action',
        key: 'x',
        render: (v: any, u: any) => <Tooltip title="View user details" placement="top"><Button type="link" onClick={() => navigate(`/users/${u.uid}`)}><EyeTwoTone /></Button></Tooltip>,
    });

    return (
        <MotionBox>
            
                <Card
                    title={<><FontAwesomeIcon icon={faUser}></FontAwesomeIcon>{' '}{title}</>}
                    className="userList"
                    extra={
                        <Space size="small">
                            <Input type="text" placeholder="Search" name="search" className="search" onChange={(e: any) => setSearch(e.target.value)} />
                            <Link to="/users/create">
                                <Button type="primary" icon={<UserAddOutlined />}>
                                    Add new user
                                </Button>
                            </Link>
                        </Space>
                    }
                >
                    <Table
                        dataSource={users.filter((u: UserProfile) => {
                            if (search === '') return u;
                            else if (u.firstName && u.firstName.toLowerCase().includes(search.toLowerCase())) return u;
                            else if (u.lastName && u.lastName.toLowerCase().includes(search.toLowerCase())) return u;
                            else if (u.email && u.email.toLowerCase().includes(search.toLowerCase())) return u;
                            else if (u.company && u.company.toLowerCase().includes(search.toLowerCase())) return u;
                            else if (u?.namespaceId && getNamespaceName(u?.namespaceId)?.toLowerCase()) return u;
                            else if (u.status && u.status.toLowerCase().includes(search.toLowerCase())) return u;
                            else return null;
                        })}
                        columns={columns}
                        rowKey="uid"
                        scroll={{ x: 'max-content' }}
                        pagination={{
                            showSizeChanger: users.length > 10,
                            pageSizeOptions: ['10', '20', '30', '40', '50'],
                            defaultPageSize: 10
                        }}
                    />
                </Card>
            
        </MotionBox>
    )
}
