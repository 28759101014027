import './reset.css';
import './index.css';
import './assets/scss/common.scss';

import Application from './Application';
import { BrowserRouter } from 'react-router-dom';
import ConfigContextProvider from './contexts/ConfigContext';
import ErrorBoundary from './components/ErrorBoundary';
import ErrorPage from './components/Layout/ErrorPage';
import React from 'react';
import ReactDOM from 'react-dom/client';
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import reportWebVitals from './reportWebVitals';
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import weekday from 'dayjs/plugin/weekday'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <ErrorBoundary fallback={<ErrorPage />}>
            <ConfigContextProvider>
                <BrowserRouter>
                    <Application />
                </BrowserRouter>
            </ConfigContextProvider>
        </ErrorBoundary>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
