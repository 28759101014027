import { Col, Flex, Result, Row, Typography } from 'antd'

import { ResultStatusType } from 'antd/es/result';
import { getClusterStatus } from '../../../utils/clusterStatusUtils';

const ClusterStatusIllustration = (props:any) => {
    const { clusterStatus } = props;
    // console.log("clusterStatus", clusterStatus)

    const status = getClusterStatus(clusterStatus?.status) as ResultStatusType;
    const title = clusterStatus?.message?.split(' [')[0];
    const subtitle = clusterStatus?.message?.match(/\[(.*?)\]/)?.[1];
    return (
    <>
        <Flex justify='center' align='middle' style={{textAlign:'center'}}>
            <Row gutter={[24,24]}>
                <Col xs={24}>
                    <Result
                        status={status}
                        title={<Typography.Title level={3}>{clusterStatus && title}</Typography.Title>}
                        subTitle={clusterStatus && subtitle}
                    />
                </Col>
                {/* <Col xs={24}>Last updated Today: HH:MM:SS</Col> */}
            </Row>
        </Flex>
    </>
  )
}

export default ClusterStatusIllustration