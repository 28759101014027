import { Card, Col, Typography } from 'antd';
import { CheckOutlined, LinkOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';

import Markdown from 'react-markdown';
import { useEffect } from 'react';

//TODO : HANDLE THE TITLES SIZE IN MOBILE


const ReleaseNotesList = (props:any) => {
    const { releases, id, version } = props;
    const navigate = useNavigate();
    const location = useLocation();

    const scrollToElement = (elementId: string) => {
        const element = document.getElementById(elementId);
        if (element) {
            const yOffset = -160; // Adjust this value to set the top padding
            const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
            window.scrollTo({ top: y });
        }
    };

    //render element on select
    useEffect(() => {
        if (!id) {
            navigate(`/release-notes/${version}`);
        } else {
            scrollToElement(id);
        }
    }, [id, navigate, version]);

    //go to path element
    useEffect(() => {
        const hash = location.hash.replace('#', '');
        if (hash) {
            scrollToElement(hash);
        }
    }, [location]);

    return releases.map((release:any, index:number) =>
    {
        console.log("release",release)
        const id = release.title.toLowerCase().replace(/\s+/g, '').replace(/\//g, '-');
        return (
            <Col key={index} xs={24}>
            <Card
                title={
                <Typography.Title
                    copyable={{
                    text: `${window.location.origin}${window.location.pathname}#${id}`,
                    icon: [<LinkOutlined key="copy-icon" />, <CheckOutlined key="copied-icon" />],
                    }}
                    style={{ border: 'none' }}
                    level={2}
                    id={id}
                    className="releaseTitle"
                >
                    {release.title}
                </Typography.Title>
                }
                className="sectionCard releaseNotes"
            >
                {release.content.map((section:any, index:number) => (
                <div key={index}>
                    <div className="releaseNotesTitle">
                        <Typography.Title level={2} className="sectionTitle">
                            {section.icon}{' '}{section.subTitle}
                        </Typography.Title>
                    </div>
                    <div className="releaseNotesContent"><Markdown>{section.subContent}</Markdown></div>
                </div>
                ))}
            </Card>
            </Col>
        );
})
}

export default ReleaseNotesList