import { Button, Card, Col, List, Row, Statistic, Typography } from 'antd'

import { EnvContext } from '../../contexts';
import { Link } from 'react-router-dom';
import { UpOutlined } from '@ant-design/icons'
import { WidgetCard } from '../WidgetCard/WidgetCard'
import dayjs from 'dayjs';
import { useContext } from 'react'
import useLists from '../../hooks/useLists';

export const ListsRecordsStatsWidget = () => {

    const toDateInputValue = (date: Date) => {
        let local: Date = date;
        local.setMinutes(local.getMinutes() - local.getTimezoneOffset());
        return local.toJSON().slice(0, 10);
    }
    //start date for init
    let d: Date = new Date();
    d.setDate(d.getDate() - 2);

    const env: string = useContext(EnvContext);

    const startDate: any = toDateInputValue(d);
    const endDate: any = toDateInputValue(new Date());

    const { lists, loading, error } = useLists(env, dayjs(startDate).format('YYYYMMDD'), dayjs(endDate).format('YYYYMMDD'));

    const getStatIcon = (value: number) => {
        if (value === undefined) return (null);
        return value > 0 ? <UpOutlined /> : null;
    }

    const getStatColor = (value: number) => {
        return value > 0 ? { color: '#3f8600' } : { color: 'rgba(0, 0, 0, 0.88)' }
    }
    return (
        <WidgetCard title="Lists Status" className="list-status-widget" extra={<Link to="/list-status"><Button >View all</Button></Link>} bordered={false}>
            {(!loading && error === null && (lists.length > 0)) &&
                <List className='list-status-list' dataSource={lists}
                loading={loading}  pagination={{
                    pageSize: 1,
                    align: 'center',
                  }}
                  renderItem={(item: any) => (item.history.length > 0) &&
                    <div key={item.index}>
                        <Row gutter={[24, 24]}>
                            <Col xs={24}>
                                <Typography.Title level={4} style={{textAlign:"center"}}>{item.name}</Typography.Title>
                            </Col>
                            <Col xs={24} md={12} xxl={6}>
                                <Card>
                                    <Statistic
                                        title="List Size"
                                        value={item.size}
                                    />
                                </Card>
                            </Col>
                            <Col xs={24} md={12} xxl={6}>
                                <Card>
                                    <Statistic
                                        title="New records"
                                        value={item.history && item.history[item.history.length - 1]?.database.newRecords}
                                        prefix={item.history && getStatIcon(item.history[item.history.length - 1]?.database.newRecords)}
                                        valueStyle={item.history && getStatColor(item.history[item.history.length - 1]?.database.newRecords)}
                                    />
                                </Card>
                            </Col>

                            <Col xs={24} md={12} xxl={6}>
                                <Card><Statistic
                                    title="Deleted records"
                                    value={item.history && item.history[item.history.length - 1]?.database.deletedRecords + item.history[item.history.length - 1].database.purgedRecords}
                                    prefix={item.history && getStatIcon(item.history[item.history.length - 1]?.database.deletedRecords + item.history[item.history.length - 1].database.purgedRecords)}
                                    valueStyle={item.history && getStatColor(item.history[item.history.length - 1]?.database.deletedRecords + item.history[item.history.length - 1].database.purgedRecords)}
                                /></Card>
                            </Col>
                            <Col xs={24} md={12} xxl={6} >
                                <Card>
                                    <Statistic
                                        title="Updated records"
                                        value={item.history && item.history[item.history.length - 1]?.database.updatedRecords}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    }/>
                }
                    </WidgetCard>
    )
}