import { SecureWithLogin, useOidcAccessToken } from '../../../../utils/secure';
import { useEffect, useState } from 'react'

import Loading from '../../../Layout/Loading';
import MotionBox from '../../../Layout/Animated/MotionBox';
import ServiceAccountsList from './ServiceAccountsList'
import { useOutletContext } from 'react-router-dom';

export default function NSAdminServiceAccounts() {
    const outletContext: { setHeaderTitle: React.Dispatch<React.SetStateAction<string>> } = useOutletContext();
    useEffect(() => {
        outletContext?.setHeaderTitle("My Service Accounts");
    }, [outletContext]);

    const { accessTokenPayload } = useOidcAccessToken();
    const [namespaceId, setNamespaceId] = useState<string>("");


    useEffect(() => {
        if (accessTokenPayload?.namespaceId) {
            setNamespaceId(accessTokenPayload?.namespaceId);
        }
    }, [accessTokenPayload]);

    return (
        <SecureWithLogin callbackPath="/nsadmin" role={['nsadmin_service_accounts']}>
            {namespaceId !== undefined ?
                <MotionBox>
                    <ServiceAccountsList namespaceId={namespaceId} />
                </MotionBox>
                :
                <Loading />
            }
        </SecureWithLogin>
    )
}
