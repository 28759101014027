import { Table, Tag } from 'antd';

import Loading from '../../Layout/Loading';
import VolumeProgress from '../../Layout/VolumeProgress';
import useAllProducts from '../../../hooks/useAllProducts';

const NSAPackageVolumes = (props:any) => {
    const { pkg } = props;
    const { products } = useAllProducts();
    console.log("pkginvolumes", pkg);

    return (
        pkg  ? <Table
            dataSource={pkg.volumes}
            columns={[
                {
                    title: 'Engine',
                    dataIndex: 'product',
                    key: 'product',
                    render: (product: string) => {
                        const p = products.find((p: Product) => p?.value.toLowerCase() === product?.toLowerCase());
                        return (
                            <Tag color={p?.color}>{product}</Tag>
                        )
                    }
                },
                {
                    title: 'Collection',
                    dataIndex: 'collection',
                    key: 'collection'
                },
                {
                    title: 'Amount',
                    dataIndex: 'amount',
                    key: 'amount'
                },
                {
                    title: 'Cycle',
                    dataIndex: 'cycle',
                    key: 'cycle'
                },
                {
                    title: 'Options',
                    dataIndex: 'options',
                    key: 'options',
                    render: (o: any) => {
                        if (o === null) {
                            return "None"
                        }
                        return o?.join(', ')
                    }
                },
                {
                    title: 'Usage',
                    key: 'usage',
                    render: (v: any) => {
                        return <VolumeProgress pack={pkg} volume={v} type="line" />
                    }
                }
            ]}
            rowKey="collection"
            pagination={false}
        /> :
        <Loading />
    )
}

export default NSAPackageVolumes