import '../assets/scss/Home.scss';

import { Alert, Col, Row } from 'antd';
import { HomeFilled, HomeOutlined } from '@ant-design/icons';
import { ShowIfAccess, useOidcUser } from '../utils/secure';

// import ClusterStatusWidget from './clusterStatus/ClusterStatusWidget';
import { Content } from 'antd/es/layout/layout';
import FullClusterStatusWidget from './widgets/FullClusterStatusWidget';
import { ListsRecordsStatsWidget } from './widgets/ListsRecordsStatsWidget';
import LoginEmail from './login/LoginEmail';
import MotionBox from './Layout/Animated/MotionBox';
import { PageHeader } from './Layout/header/PageHeader';
import { ProductStatsContainer } from './widgets/ProductStatsContainer';
import useUserInfos from '../hooks/useUserInfos';

// import { useFeatures } from '../hooks/useFeatures';


const Home = () => {

    // const { features } = useFeatures();
    const { oidcUser }: { oidcUser: any } = useOidcUser();
    const { userInfos, loading: loadingUser, error: errorUser } = useUserInfos(oidcUser?.jetUserId);
    // const title = `Welcome to your Neterium Dashboard, ${oidcUser?.given_name ? oidcUser?.given_name : ''}`;

    return (
        <section className="homepage">
            <ShowIfAccess authenticated={true}>
                <MotionBox>
                    <Content className='features markdown'>
                        <PageHeader icon={<HomeFilled />} withEnvSelector title={`Welcome to your Dashboard, ${oidcUser?.given_name}`} subtitle='Dashboard' breadcrumbs={[{ title: <span><HomeOutlined />&nbsp;Home</span>, href: '/', }]} />
                        <Content>
                            {userInfos && !loadingUser && errorUser === null && userInfos?.formattedContent?.status?.display &&
                                <Row gutter={[24, 24]} >
                                    <Col xs={24} >
                                        <Alert
                                            message={userInfos?.formattedContent?.status?.message}
                                            type={userInfos?.formattedContent?.status?.type}
                                            showIcon />
                                    </Col>
                                </Row>
                            }
                                <Row gutter={[24,24]}>
                                    <Col xs={24} xl={16}>
                                        <Row gutter={[24,24]}>
                                            {/* <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={16} >
                                                <InfoWidget user={oidcUser?.given_name} />
                                            </Col> */}
                                            {/* Access to the following widgets is restricted by Stats role:*/}
                                            <ShowIfAccess role={['js_stats']} authenticated={true}>
                                                <Col  xs={24}>
                                                    <ProductStatsContainer />
                                                </Col>
                                            </ShowIfAccess>
                                            {/* Access to the following widgets is restricted by List role:*/}
                                            <ShowIfAccess role={['portal_list_status', 'js_stats']} authenticated={true}>
                                                <Col xs={24}>
                                                    <ListsRecordsStatsWidget />
                                                </Col>
                                            </ShowIfAccess>
                                            {/* Access to the following widgets is restricted by Cluster/Service role:*/}
                                            <ShowIfAccess role={['nsadmin_packages']} authenticated={true}>
                                                <Col xs={24} >
                                                    {/* <PackagesWidget /> */}
                                                </Col>
                                            </ShowIfAccess>
                                        </Row>
                                    </Col>
                                    <Col xs={24} xl={8}>
                                        <Row gutter={[24,24]}>
                                            {/* Access to the following widgets is restricted by Cluster/Service role:*/}
                                            <ShowIfAccess role={['portal_cluster_status']} authenticated={true}>
                                                <Col xs={24}>
                                                    <FullClusterStatusWidget />
                                                </Col>
                                            </ShowIfAccess>
                                            {/* Access to the following widgets is restricted by News role:*/}
                                            <ShowIfAccess authenticated={true}>
                                                <Col xs={24}>
                                                    {/* <LatestNewsWidget /> */}
                                                </Col>
                                            </ShowIfAccess>
                                        </Row>
                                    </Col>
                                </Row>
                        </Content>
                    </Content>
                </MotionBox>
            </ShowIfAccess>
            <ShowIfAccess authenticated={false}>
                <MotionBox>
                    <LoginEmail />
                </MotionBox>
            </ShowIfAccess>
        </section>
    );
}

export default Home;
