import { useEffect, useState } from "react";

import { callBackEnd } from "../utils/backEndUtils";
import moment from "moment";
import { useOidcAccessToken } from '../utils/secure';

export default function useStats(env: string, fromDate: string, toDate: string, client: string, project: string, product: string, pack: string, collection: string) {

    const { accessTokenPayload } = useOidcAccessToken();

    const [stats, setStats] = useState<any>([]);
    const [currentMonthStats, setCurrentMonthStats] = useState<any>([]);
    const [currentYearStats, setCurrentYearStats] = useState<any>([]);

    const [envAttributes, setEnvAttributes] = useState<EnvAttributes | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);

    const [refreshKey, setRefreshKey] = useState<number>(0);

    const targetEnv: string = `${env}`;


    useEffect(() => {
        const getEnvVariables = (env: string): EnvAttributes => {
            const envAttributes: any = accessTokenPayload?.attributes?.hasOwnProperty(targetEnv)
                ? accessTokenPayload.attributes[targetEnv]
                : {
                    jetscan: {
                        volume: -1,
                        cycle: 'daily'
                    },
                    jetflow: {
                        volume: -1,
                        cycle: 'daily'
                    }
                };

            return envAttributes;
        }
        //Set volumes and cyles in the state
        setEnvAttributes(getEnvVariables(targetEnv));
    }, [accessTokenPayload, targetEnv]);

    useEffect(() => {

        async function getStats() {
            try {
                setLoading(true);

                let data: string = JSON.stringify({
                    fromDate,
                    toDate,
                    ...(client !== '' && { client }),
                    ...(project !== '' && { project }),
                    ...(pack !== '' && { packageId: pack }),
                    ...(collection !== '' && { collection })
                })
                console.log(data);
                //Stats in range with filter
                const response: any = await callBackEnd(targetEnv, 'POST', 'api/v1/core/stats', data).catch((e: any) => { setError(e) });

                if (response?.data && Array.isArray(response?.data)) {
                    setStats(response.data);
                    setError(null);
                } else {
                    setError('Invalid response from API');
                    throw new Error('Invalid response from API');
                }
                //Stats this month (used too for daily stats)
                const responseMonthly: any = await callBackEnd(targetEnv, 'POST', 'api/v1/core/stats', JSON.stringify({
                    fromDate: moment().startOf('month').format('YYYY/MM/DD'),
                    endDate: moment().endOf('month').format('YYYY/MM/DD')
                })).catch((e: any) => { setError(e) });
                if (Array.isArray(responseMonthly.data)) {
                    setCurrentMonthStats(responseMonthly.data);
                } else {
                    setError('Invalid response from API');
                    throw new Error('Invalid response from API');
                }

                //Stats this year
                const responseYear: any = await callBackEnd(targetEnv, 'POST', 'api/v1/core/stats', JSON.stringify({
                    fromDate: moment().startOf('year').format('YYYY/MM/DD'),
                    endDate: moment().endOf('year').format('YYYY/MM/DD')
                })).catch((e: any) => { setError(e) });
                if (Array.isArray(responseYear.data)) {
                    setCurrentYearStats(responseYear.data);
                } else {
                    setError('Invalid response from API');
                    throw new Error('Invalid response from API');
                }

            } catch (error: any) {
                setError(error);
            } finally {
                setLoading(false);
            }
        }
        getStats().catch((e: any) => { console.log(e) });
    }, [targetEnv, fromDate, toDate, client, project, product, pack, refreshKey,collection]);

    const incrementRefreshKey = () => {
        setRefreshKey(prevKey => prevKey + 1);
    };


    return { stats, loading, error, envAttributes, currentMonthStats, currentYearStats, incrementRefreshKey }

}