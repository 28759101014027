import { Card, Col, Flex, List, Row } from 'antd'

import CollectionStatus from './CollectionStatus';
import React from 'react'

const ClusterStatusCollectionsStatus = (props:any) => {
    const { clusterStatus, env} = props;
    return (
    <>
        <Flex vertical >
            <Row justify='center' align='middle' gutter={[24,24]}>
                <Col xs={24} lg={18} xxl={12}>
                    <Card title="Collections Status" style={{ width: '100%'}} >
                        <List
                            itemLayout="horizontal"
                            dataSource={clusterStatus?.availableCollections}
                            rowKey={(item: string) => item}
                            renderItem={(item: string) => <List.Item><CollectionStatus targetRealm={env} collection={item} /></List.Item>}
                        />
                    </Card>
                </Col>
            </Row>
        </Flex>
    </>
    )
}

export default ClusterStatusCollectionsStatus