import '../../../../assets/scss/InviteUsers.scss';

import { Button, Card, Form, Input, Space, notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { Content } from 'antd/es/layout/layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MotionBox from "../../../Layout/Animated/MotionBox";
import { SecureWithLogin } from "../../../../utils/secure";
import { callBackEnd } from '../../../../utils/backEndUtils';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

export default function NSAInviteForm() {
    const [form] = Form.useForm();
    const { namespaceId } = useParams<{ namespaceId: string }>();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    
    if (namespaceId === undefined) {
        throw new Error("namespaceId is undefined");
    }
    const navigate = useNavigate();

    // const [seletedRoles, setSelectedRoles] = useState<string[]>([]);

    const formData: InviteUsersFormDataType = {
        email: '',
        firstName: '',
        lastName: '',
        company: ''
        // roles: []
    }
    const handleForm = async (values: any) => {

        setIsProcessing(true);

        formData.email = values.email;
        formData.firstName = values.firstName;
        formData.lastName = values.lastName;
        formData.company = values.company;

        await callBackEnd('portal', 'post', `namespaces/${namespaceId}/users`, JSON.stringify(formData)).catch((e: any) => {
            notification.error({ message: `Error inviting user ${formData.email}` });
            console.log(e)
        }).then((response: any) => {
            setIsProcessing(false);
            notification.success({message: `User ${formData.email} has been invited to join your namespace`});
            navigate(`/nsadmin/users/`);
        });


    }

    return (
        <SecureWithLogin callbackPath="/nsadmin" role={['nsadmin_invites_users']}>
            {/* could be nsadmin_invite */}
            <MotionBox>
                <Content>
                    <Card title="Invite Users to your Namespace">
                        <Form
                            labelWrap
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 21 }}
                            form={form}
                            onFinish={handleForm}
                        >
                            <Form.Item
                                label="E-Mail"
                                name="email"
                                rules={[{ type: 'email', required: true, message: 'Please input your e-mail!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="First Name"
                                name="firstName"
                                rules={[{ required: true, message: 'Please input the first name!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Last Name"
                                name="lastName"
                                rules={[{ required: true, message: 'Please input the last name!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Company"
                                name="company"
                                rules={[{ required: true, message: 'Please input the company!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 3, span: 21 }}>
                                <Space size="middle">
                                    <Button type="text" onClick={() => navigate(`/nsadmin/users`)}>
                                        Cancel
                                    </Button>
                                    <Button type="primary" htmlType="submit" loading={isProcessing} icon={<FontAwesomeIcon icon={faPaperPlane} />}>
                                        Send Invite
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    </Card>
                </Content>
            </MotionBox>
        </SecureWithLogin>
    )
}
