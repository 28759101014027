import './../../../assets/scss/PageHeader.scss';

import { BreadcrumbProps, Col, Divider, Flex, Row, Typography } from 'antd';

import EnvSelector from '../../environments/EnvSelector';
import React from 'react';

type Props = {
    icon?: React.ReactNode;
    withEnvSelector?: boolean;
    title: string;
    subtitle: string;
    breadcrumbs?: BreadcrumbProps['items'];
} & React.HTMLAttributes<HTMLDivElement>;

export const PageHeader = ({ icon, breadcrumbs, title, subtitle, withEnvSelector, ...others }: Props) => {
    return (
        <div {...others}>
            <Row gutter={[24, 24]}>
                <Col xs={24} xl={16} xxl={18}>
                    <Typography.Title className='page-title' level={1} style={{ padding: 0, margin: 0, textTransform: 'capitalize' }}>{title}</Typography.Title>
                </Col>
                {withEnvSelector &&<Col xs={24} xl={8} xxl={6} className="env-selector">
                    <Flex vertical justify='center' style={{}}> <EnvSelector />
                    </Flex>
                </Col>}
            </Row>
            <Divider orientation="right" plain>
                <span style={{ textTransform: 'capitalize' }}>{subtitle}</span>
            </Divider>
        </div>
    );
};