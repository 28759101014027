import '../../../assets/scss/CreateForms.scss';

import { Button, Card, Form, Input, Space, notification } from 'antd';
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Content } from 'antd/es/layout/layout';
import CustomModal from "../common/CustomModal";
import MotionBox from '../../Layout/Animated/MotionBox';
import { SecureWithLogin } from "../../../utils/secure";
import { callBackEnd } from "../../../utils/backEndUtils";
import { useForm } from 'antd/es/form/Form';

export default function FormUsers() {
    const navigate = useNavigate();
    const [form] = useForm();

    const { userId } = useParams();
    const formMode: string = userId ? 'edit' : 'create';

    const [editedUser, setEditedUser] = useState<UserProfile | null>(null);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    

    const formData: UserFormDataType = {
        firstName: '',
        lastName: '',
        email: '',
        company: '',
    }
    //Get the data for edit mode
    useEffect(() => {

        async function getUser() {
            try {
                const response: any = await callBackEnd('portal', 'get', `users/${userId}`);
                setEditedUser(response);
                form.setFieldsValue({
                    firstName: response.firstName,
                    lastName: response.lastName,
                    email: response.email,
                    company: response.company
                });
            } catch (error: any) {
                console.log(error);
            }
        }

        if (formMode === 'edit' && userId) {
            getUser().catch((e: any) => console.log(e));
        }
    }, [userId, formMode,form]);

    const handleFormCreateUser = (values:any) => {
        async function saveUser(data: UserFormDataType) {
            try {

                if (formMode === 'edit' && userId) {
                    //edit
                    await callBackEnd('portal', 'PUT', `users/${userId}`, JSON.stringify(data));
                    notification.success({ message: "User edited successfully" });
                    navigate(`/users/${userId}`);
                }
                else {
                    //create
                    await callBackEnd('portal', 'post', `users`, JSON.stringify(data));
                    notification.success({ message:"User created successfully"});
                    navigate(`/users`);
                }
            } catch (error: any) {
                console.log(error);
            }
        }

        formData.firstName = values.firstName;
        formData.lastName = values.lastName;
        formData.email = values.email;
        formData.company = values.company;
        
        saveUser(formData).catch((e: any) => console.log(e));

    }

    const handleDeleteUser = (userId: any) => {

        //TODO: pas de delete dans le cas d'un service account actif, retourner l'erreur

        async function deleteUser(id: string) {
            try {
                await callBackEnd('portal', 'DELETE', `users/${userId}`);
                navigate(`/users`);

            } catch (error: any) {
                console.log(error);
            }
        }
        deleteUser(userId).catch((e: any) => console.log(e));
    }

    const handleDeleteModalResponse = (response: boolean) => {
        if (response) {
            setShowDeleteModal(false);
            handleDeleteUser(userId);
        }
        else {
            setShowDeleteModal(false);
        }
    }

    return (
        <SecureWithLogin callbackPath="/users" role={['crm_users']}>
            <MotionBox>
                <Content>
                <Card
                    title={<>
                        {formMode === 'edit' ? `Edit: ${editedUser?.email}` : 'Create new user'}
                    </>}
                    extra={<>
                        {formMode === 'edit' &&
                            <Button type="primary" danger onClick={() => setShowDeleteModal(true)} icon={<DeleteOutlined />}>
                                Delete User
                            </Button>
                        }
                    </>}
                >
                    <Form
                            labelWrap
                        onFinish={handleFormCreateUser}
                        form={form}
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 21 }}
                    >
                        <Form.Item
                            label="Firstname"
                            name="firstName"
                            rules={[{ required: true, message: "Please input user's firstname!" }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Lastname"
                            name="lastName"
                            rules={[{ required: true, message: "Please input user's lastname!" }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ required: true, message: "Please input user's email!" }]}
                        >
                            <Input disabled={formMode === 'edit'} />
                        </Form.Item>
                        <Form.Item
                            label="Company"
                            name="company"
                            rules={[{ required: true, message: "Please input user's company!" }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 3, span: 21 }}>
                            <Space size="middle">
                                {/* <Link to={`/users/`}> */}
                                <Button type="text" onClick={()=>navigate(-1)}>
                                    Cancel
                                </Button>
                                {/* </Link> */}
                                <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                                    Save
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Card>
                </Content>
            </MotionBox>
            {showDeleteModal && editedUser !== null &&
                <CustomModal secureString={editedUser.email} title="Delete user" message={`Are you sure you want to delete the user ${editedUser.email} ? Please type in the name of the user "${editedUser.email}" as a confirmation.`} callback={handleDeleteModalResponse} />
            }
        </SecureWithLogin>
    )
}
