import { Button, Card, Descriptions, Divider, Space, Tooltip, Typography, notification } from 'antd';
import { Link, useParams } from "react-router-dom";
import { SecureWithLogin, useOidcUser } from "../../../utils/secure";
import { faCheck, faPenToSquare, faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { faCircle, faCircleCheck, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from "react";

import CustomModal from '../common/CustomModal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../Layout/Loading";
import Moment from 'react-moment';
import MotionBox from '../../Layout/Animated/MotionBox';
import ShowError from '../../Layout/ShowError';
import { callBackEnd } from "../../../utils/backEndUtils";

export default function User() {


    const { userId } = useParams();

    const [loadingUser, setLoadingUser] = useState<boolean>(true);
    const [errorUser, setErrorUser] = useState<any>(null);
    const [user, setUser] = useState<UserProfile | null>(null);
    const [showDisableModal, setShowDisableModal] = useState<boolean>(false);
    const [showEnableModal, setShowEnableModal] = useState<boolean>(false);
    const [showVerifyModal, setShowVerifyModal] = useState<boolean>(false);
    const [showRejectModal, setShowRejectModal] = useState<boolean>(false);

    const [namespaceDetails, setNamespaceDetails] = useState<Namespace | null>(null);

    const { oidcUser }: { oidcUser: any } = useOidcUser();
    const isRestricted: boolean = oidcUser?.jetUserId === user?.uid; //user can't edit himself

    useEffect(() => {
        async function getUser() {
            try {
                setLoadingUser(true);
                const response: any = await callBackEnd('portal', 'get', `users/${userId}`);
                setUser(response);

                if (response?.namespaceId) {
                    const namespaceResponse: any = await callBackEnd('portal', 'get', `namespaces/${response.namespaceId}`);
                    setNamespaceDetails(namespaceResponse);
                }

            } catch (error: any) {
                setErrorUser(error);
            } finally {
                setLoadingUser(false);
            }
        }
        getUser().catch((e: any) => console.log(e));
    }, [userId]);

    const handleUpdateUser = (userId: any) => {
        async function updateUser(id: string) {
            try {
                const response: any = await callBackEnd('portal', 'PUT', `users/${id}/enabled`);
                setUser(response);
                notification.success({message: "User succefully updated"});
            } catch (error: any) {
                console.log(error);
            }
        }
        if (!isRestricted) {
            updateUser(userId).catch((e: any) => console.log(e));
        }
        else {
            notification.error({message: "You can't edit your own account",duration:0});
        }
    }

    const handleVerifyUser = (userId: any) => {
        async function verifyUser(id: string) {
            try {
                const response: any = await callBackEnd('portal', 'PUT', `users/${id}/verify`);
                setUser(response);
                notification.success({message: "User succefully verified"});
            } catch (error: any) {
                console.log(error);
            }
        }
        if (!isRestricted) {
            verifyUser(userId).catch((e: any) => console.log(e));
        }
        else {
            notification.error({ message: "You can't edit your own account", duration: 0 });
        }
    }
    const handleRejectUser = (userId: any) => {
        async function rejectUser(id: string) {
            try {
                const response: any = await callBackEnd('portal', 'PUT', `users/${id}/reject`);
                setUser(response);
                notification.success({ message: "User succefully rejected" });
            } catch (error: any) {
                console.log(error);
            }
        }
        if (!isRestricted) {
            rejectUser(userId).catch((e: any) => console.log(e));
        }
        else {
            notification.error({ message: "You can't edit your own account", duration: 0 });
        }
    }

    const handleDisableModalResponse = (response: boolean) => {
        if (response) {
            setShowDisableModal(false);
            handleUpdateUser(userId);
        }
        else {
            setShowDisableModal(false);
        }
    }
    const handleEnableModalResponse = (response: boolean) => {
        if (response) {
            setShowEnableModal(false);
            handleUpdateUser(userId);
        }
        else {
            setShowEnableModal(false);
        }
    }
    const handleVerifyModalResponse = (response: boolean) => {
        if (response) {
            setShowVerifyModal(false);
            handleVerifyUser(userId);
        }
        else {
            setShowVerifyModal(false);
        }
    }
    const handleRejectModalResponse = (response: boolean) => {
        if (response) {
            setShowRejectModal(false);
            handleRejectUser(userId);
        }
        else {
            setShowRejectModal(false);
        }
    }

    const showVerifiedStatus = (value: string | boolean) => {
        let icon: any = null;
        let classColor: string = '';
        switch (value) {
            case 'REGISTERED':
            case 'NAMESPACE_JOIN_REQUESTED':
                icon = faCircle;
                classColor = 'orange';
                break;
            case 'VERIFIED':
            case true:
                icon = faCircleCheck;
                classColor = 'green';
                break;
            case 'REJECTED':
            case false:
                classColor = 'red';
                icon = faXmarkCircle;
                break;
        }
        return (
            <span className={`${classColor} userPill`}>
                <FontAwesomeIcon icon={icon} />
            </span>
        )
    }

    return (
        <SecureWithLogin callbackPath="/users" role={['crm_users']}>
            {(errorUser === null && !loadingUser && user !== null) ?
                <MotionBox>
                    <Card
                        title={<>User: {user.email}</>}
                        className="users"
                        extra={
                            <Space size="middle">
                                <Link to={`/users/namespace/${userId}`}>
                                    <Tooltip title={isRestricted && "You can not edit your own account"}>
                                        <Button type="primary" icon={<FontAwesomeIcon icon={faPenToSquare}></FontAwesomeIcon>} disabled={isRestricted}>
                                            Assign to Namespace
                                        </Button>
                                    </Tooltip>
                                </Link>
                                <Link to={`/users/edit/${userId}`}>
                                    <Button type="primary" icon={<FontAwesomeIcon icon={faPenToSquare}></FontAwesomeIcon>}>
                                        Edit profile
                                    </Button>
                                </Link>
                                {(user.status === 'VERIFIED' && namespaceDetails !== null) &&
                                    <Link to={`/users/${userId}/${namespaceDetails.uid}/roles`}>
                                        <Tooltip title={isRestricted && "You can not edit your own account"}>
                                            <Button type="primary" icon={<FontAwesomeIcon icon={faPenToSquare}></FontAwesomeIcon>} disabled={isRestricted}>
                                                Edit roles
                                            </Button>
                                        </Tooltip>
                                    </Link>
                                }
                                {user.status !== 'VERIFIED' &&
                                    <Tooltip title={isRestricted && "You can not edit your own account"}>
                                        <Button type="primary" onClick={() => setShowVerifyModal(true)} icon={<FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>} disabled={isRestricted}>
                                            Verify user
                                        </Button>
                                    </Tooltip>

                                }
                                {(namespaceDetails === null) &&
                                    <Tooltip title={isRestricted && "You can not edit your own account"}>
                                        <Button type="primary" danger onClick={() => setShowRejectModal(true)} icon={<FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>} disabled={isRestricted}>
                                            Reject user
                                        </Button>
                                    </Tooltip>
                                }
                                {user.enabled &&
                                    <Tooltip title={isRestricted && "You can not edit your own account"}>
                                        <Button type="primary" danger onClick={() => setShowDisableModal(true)} icon={<FontAwesomeIcon icon={faToggleOff}></FontAwesomeIcon>} disabled={isRestricted}>
                                            Disable user
                                        </Button>
                                    </Tooltip>
                                }
                                {!user.enabled &&
                                    <Tooltip title={isRestricted && "You can not edit your own account"}>
                                        <Button type="primary" onClick={() => setShowEnableModal(true)} icon={<FontAwesomeIcon icon={faToggleOn}></FontAwesomeIcon>} disabled={isRestricted}>
                                            Enable user
                                        </Button>
                                    </Tooltip>
                                }
                            </Space>
                        }
                    >
                        <Typography.Title level={5}>User profile</Typography.Title>
                        <Descriptions>
                            <Descriptions.Item label="Firstname">{user?.firstName}</Descriptions.Item>
                            <Descriptions.Item label="Lastname">{user?.lastName}</Descriptions.Item>
                            <Descriptions.Item label="E-Mail">{user?.email}</Descriptions.Item>
                            <Descriptions.Item label="Company">{user?.company}</Descriptions.Item>
                            {namespaceDetails &&
                                <Descriptions.Item label="Namespace">
                                    <Tooltip title="Go to Namespace details">
                                        <Link to={`/namespaces/${user?.namespaceId}`}>
                                            {namespaceDetails.label} ({namespaceDetails.name})
                                        </Link>
                                    </Tooltip>
                                </Descriptions.Item>
                            }
                        </Descriptions>
                        <Divider />
                        <Typography.Title level={5}>Technical informations</Typography.Title>
                        <Descriptions>
                            <Descriptions.Item label="Uid">{user?.uid}</Descriptions.Item>
                            <Descriptions.Item label="Enabled"><Space size={3} align='center'>{showVerifiedStatus(user?.enabled)} {user?.enabled.toString()}</Space></Descriptions.Item>
                            <Descriptions.Item label="Status"><Space size={3} align='center'>{showVerifiedStatus(user?.status)}{user?.status}</Space></Descriptions.Item>
                            <Descriptions.Item label="Last login"><Moment date={user?.lastLogin} /></Descriptions.Item>
                        </Descriptions>

                    </Card>

                    {showDisableModal && user !== null &&
                        <CustomModal secureString={user.email} title="Disable user" message={`Are you sure you want to disable the user ${user.email} ? Please type in the name of the user "${user.email}" as a confirmation.`} callback={handleDisableModalResponse} />
                    }
                    {showEnableModal && user !== null &&
                        <CustomModal secureString={user.email} title="Enable user" message={`Are you sure you want to enable the user ${user.email} ? Please type in the name of the user "${user.email}" as a confirmation.`} callback={handleEnableModalResponse} />
                    }
                    {showVerifyModal && user !== null &&
                        <CustomModal title="Verify user" message={`Are you sure you want to verify the user ${user.email} ?`} callback={handleVerifyModalResponse} />
                    }
                    {showRejectModal && user !== null &&
                        <CustomModal title="Reject user" message={`Are you sure you want to reject the user ${user.email} ?`} callback={handleRejectModalResponse} />
                    }
                </MotionBox>
                :
                <>
                    {loadingUser && <Loading />}
                    {errorUser && <ShowError error={errorUser} />}
                </>
            }
        </SecureWithLogin>
    )
}
