import '../../assets/scss/NavBar.scss';

import { ShowIfAccess, useOidc, useOidcAccessToken, useOidcUser } from '../../utils/secure';
import { useContext, useEffect, useState } from 'react';

import AccountMenu from './AccountMenu';
import { EnvContext } from '../../contexts/EnvironmentContext';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from './../../assets/img/neteriumLogoFull.svg';
import { callBackEnd } from '../../utils/backEndUtils';

export default function NavBar() {

    const { isAuthenticated, renewTokens } = useOidc();
    const { oidcUser, }: { oidcUser: any } = useOidcUser();
    const { accessToken, accessTokenPayload } = useOidcAccessToken();

    // const isAuthenticated = oidcUser !== null;
    const [namespace, setNamespace] = useState<Namespace | null>(null);
    const env = useContext(EnvContext);
    // const updateEnvironment = useContext(EnvUpdateContext);


    // const handleChangeEnvironment = (value: EnvType) => {
    //     if (updateEnvironment) {
    //         updateEnvironment(value);
    //     }
    // }

    useEffect(() => {
        async function getNamespace() {
            try {
                const response: any = await callBackEnd('portal', 'get', `namespaces/${oidcUser.namespaceId}`);
                setNamespace(response);

            } catch (error: any) {
                console.log(error);
            }
        }

        if (oidcUser?.namespaceId) {
            getNamespace().catch((e: any) => console.log(e));
        }
    }, [oidcUser, isAuthenticated, accessToken, accessTokenPayload]);

    return (
        <nav className={`navbar ${oidcUser && env}`}>
            <div className="logo">
                <Link to={'/'}>
                    <Logo onClick={() => {
                        renewTokens();
                        window.location.assign(window.location.origin);
                    }} />
                </Link>
                {(namespace !== null && namespace.label !== '' && namespace.label.length > 0) &&
                    <span>{namespace?.label}</span>
                }

            </div>
            <ul className="links">
                {/* <ShowIfAccess authenticated={false}>
                    <li>
                        <Link to={'/login'}>
                            <FontAwesomeIcon icon={faArrowRightToBracket} inverse />
                            {' '}
                            Register / Login
                        </Link>
                    </li>
                </ShowIfAccess>

                <ShowIfAccess authenticated={false}>
                    <li>
                        <Link to={'/login/namespace/neterium'}>
                            <FontAwesomeIcon icon={faArrowRightToBracket} inverse />
                            {' '}
                            Login with Namespace
                        </Link>
                    </li>
                </ShowIfAccess> */}
                {/* {allEnv.length > 0 &&
                    <ShowIfAccess authenticated={true}>
                        <li className="envSelector">
                            <select name="environment" className={`environment ${env}`} id="environment" value={env} onChange={(e: any) => handleChangeEnvironment(e.target.value)}>
                                {allEnv.map((env: any) => {
                                    return <option key={env.name} value={env.name}>{env.label}</option>
                                })}
                            </select>
                        </li>
                    </ShowIfAccess>
                } */}

                <ShowIfAccess authenticated={true}>
                    <li><AccountMenu /></li>
                </ShowIfAccess>
            </ul>
        </nav>

    );
}