import '../../../assets/scss/commonModules/PaackagesList.scss';

import { Button, Card, Drawer, Table, TableColumnsType, Tag, Tooltip } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone, EditOutlined, FolderAddOutlined, FolderOutlined, InfoCircleTwoTone, PieChartOutlined } from '@ant-design/icons';
import { SecureWithLogin, ShowIfAccess, ToggleIfAccess } from '../../../utils/secure';
import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import LoadingSkeleton from '../../Layout/LoadingSkeleton';
import Moment from 'react-moment';
import MotionBox from '../../Layout/Animated/MotionBox';
import ShowError from '../../Layout/ShowError';
import StatusPills from '../common/StatusPills';
import VolumeProgress from '../../Layout/VolumeProgress';
import { callBackEnd } from '../../../utils/backEndUtils';
import { getPackageTypes } from '../../../utils/getPackageTypes';
import useAllEnvironments from '../../../hooks/useAllEnvironments';
import useAllProducts from '../../../hooks/useAllProducts';

export default function PackagesList(props: Readonly<IPackageListProps>) {

    const { packages, namespaceId } = props as IPackageListProps;
    const { allEnv } = useAllEnvironments();

    const [loadingServiceAccounts, setLoadingServiceAccounts] = useState<boolean>(true);
    const [errorServiceAccounts, setErrorServiceAccounts] = useState<any>(null);
    const [serviceAccounts, setServiceAccounts] = useState<ServiceAccount[] | null>(null);

    const { products } = useAllProducts();

    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [selectedPackage, setSelectedPackage] = useState<Package | null>(null);

    useEffect(() => {

        async function getServiceAccounts() {
            setLoadingServiceAccounts(true);
            const response: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}/service-accounts`)
                .catch((e: any) => {
                    console.log(e);
                    setErrorServiceAccounts(e)
                }).finally(() => setLoadingServiceAccounts(false));


            setServiceAccounts(response);
        }
        if (namespaceId !== "") {
            getServiceAccounts().catch((e: any) => console.log(e));
        }
    }, [namespaceId])

    const renderOptions = (options: any) => {
        if (options?.length > 0) {
            return (
                <Tooltip
                    title={
                        <ul>
                            {options.map((o: any, index: number) => {
                                return (
                                    <li key={index}>
                                        {o.name}
                                        {/* <td>{(o.enabled) ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faBan} />}</td> */}

                                        {o.limits !== null &&
                                            <>
                                                {' > '}
                                                {
                                                    Object.keys(o.limits).map((k: any, index: number) => {
                                                        return (<small key={k.name}>
                                                            {k}
                                                            :{' '}
                                                            {o?.limits[k]}
                                                        </small>)
                                                    })
                                                }
                                            </>
                                        }
                                    </li>
                                )
                            })}
                        </ul>
                    } placement="top">
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                </Tooltip>
            )
        }
        return "None"
    }

    const showDrawerVolumes = (packageId: string) => {
        if(!packages) return;
        setSelectedPackage(packages.find((p: Package) => p?.uid === packageId) || null);
        setDrawerOpen(true);
    }

    const renderVolumesTable = (p: Package) => {
        return (
            <Table
                dataSource={p.volumes}
                columns={[
                    {
                        title: 'Engine',
                        dataIndex: 'product',
                        key: 'product',
                        render: (product: string) => {
                            const p = products.find((p: Product) => p?.value.toLowerCase() === product?.toLowerCase());
                            return (
                                <Tag color={p?.color}>{product}</Tag>
                            )
                        }
                    },
                    {
                        title: 'Collection',
                        dataIndex: 'collection',
                        key: 'collection'
                    },
                    {
                        title: 'Amount',
                        dataIndex: 'amount',
                        key: 'amount'
                    },
                    {
                        title: 'Cycle',
                        dataIndex: 'cycle',
                        key: 'cycle'
                    },
                    {
                        title: 'Options',
                        dataIndex: 'options',
                        key: 'options',
                        render: (o: any) => {
                            if (o === null) {
                                return "None"
                            }
                            return o?.join(', ')
                        }
                    },
                    {
                        title: 'Usage',
                        key: 'usage',
                        render: (v: any) => {
                            return <VolumeProgress pack={p} volume={v} type="line" />
                        }
                    }
                ]}
                rowKey="collection"
                pagination={false}
            />
        )
    }

    const countPackageIdInServiceAccount = (packageId: string) => {
        let count: number = 0;
        if (packageId) {
            serviceAccounts?.forEach((sa: ServiceAccount) => {
                if (sa.packageId === packageId) {
                    count++;
                }
            })

        }
        return count;
    }

    const columns: TableColumnsType<Package> = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => <StatusPills type={status} />,
            filters: [
                { text: 'Enabled', value: "enabled" },
                { text: 'Disabled', value: "disabled" }
            ],
            onFilter: (value: any, p: any) => p.status.toLowerCase() === value,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name: string, record: Package) => {
                return (
                    <>
                        {(record?.description) ?
                            <Tooltip title={record.description}>
                                {name} <InfoCircleTwoTone />
                            </Tooltip>
                            :
                            <>{name}</>
                        }
                    </>
                )
            }
        },
        {
            title: 'Environment',
            dataIndex: 'environment',
            key: 'environment',
            filters: allEnv.map((e: any) => {
                return { text: e.label, value: e.name }
            }),
            onFilter: (value: any, p: any) => p.environment === value,
            render: (env: string) => {
                const color: string = allEnv.find((e: any) => e.name === env)?.color;
                return <Tag color={color}>{env}</Tag>
            }
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (type: string) => {
                return <Tag color={"blue"}>{type.replace(/_/g, ' ')}</Tag>
            },
            filters: getPackageTypes().map((t: any) => {
                return { text: t.replace(/_/g, ' '), value: t }
            }
            ),
            onFilter: (value: any, p: any) => p.type === value,
        },
        {
            title: "Limit",
            dataIndex: "limit",
            key: "limit",
            render: (limit: "SOFT" | "HARD") => {
                return <Tag color={limit === "SOFT" ? "green" : "red"}>{limit}</Tag>
            }
        },
        {
            title: 'Expiration',
            dataIndex: 'expiration',
            key: 'expiration',
            render: (v: Date | null) => {
                if (v === null) {
                    return <>No expiration</>
                }
                return <Moment format="YYYY/MM/DD">{v}</Moment>
            }
        },
        {
            title: 'Options',
            dataIndex: 'options',
            key: 'options',
            render: (o: any) => renderOptions(o)
        },
        {
            title: 'User Default',
            dataIndex: 'userDefault',
            key: 'userDefault',
            render: (status: string) => <StatusPills type={status ? "enabled" : "disabled"} />,
            filters: [
                { text: 'Yes', value: true },
                { text: 'No', value: false }
            ],
            onFilter: (value: any, p: any) => p.status.toLowerCase() === value,
        },
        {
            title: 'Service Accounts',
            dataIndex: 'uid',
            key: 'uid',
            render: (uid: string) => {
                return countPackageIdInServiceAccount(uid)
            }
        },
        {
            title: 'Last Updated',
            dataIndex: 'updated',
            key: 'updated',
            sorter: (a: any, b: any) => a.updated - b.updated,
            render: (v: any) => (v) ? <Moment format="YYYY/MM/DD h:mm:ss a">{v}</Moment> : <>N/A</>
        },
        {
            title: 'Actions',
            dataIndex: 'uid',
            key: 'uid',
            render: (uid: string) => {
                return (
                    <ToggleIfAccess role={['crm_namespaces', 'crm_packages']} authenticated={true} contentPassed={
                        <>
                            <Tooltip title="Quick look at volumes" placement="top">
                                <Button type="link" icon={<PieChartOutlined />} onClick={(e: any) => showDrawerVolumes(uid)}>Volumes</Button>
                            </Tooltip>
                            <Tooltip title="Edit package" placement="top">
                                <Link to={`/namespaces/${namespaceId}/packages/${uid}`}>
                                    <Button type="link" icon={<EditOutlined />} />
                                </Link>
                            </Tooltip>
                        </>
                    }
                        contentFailed={
                            <CloseCircleTwoTone twoToneColor="#eb2f96" />
                        }
                    />
                )
            }
        }
    ];

    return (
        <SecureWithLogin callbackPath="/packages" role={['crm_packages']}>
            <MotionBox>
                <Card
                    type="inner"
                    title={<><FolderOutlined />{' '}Packages</>}
                    extra={<ShowIfAccess authenticated={true} role={['crm_namespaces', 'crm_packages']}>
                        <Link to={`/namespaces/${namespaceId}/packages/create`}>
                            <Button type="primary" icon={<FolderAddOutlined />}>Add new package</Button>
                        </Link>
                    </ShowIfAccess>
                    }
                >
                    {!loadingServiceAccounts && errorServiceAccounts === null &&
                        <Table
                            dataSource={packages}
                            columns={columns}
                            rowKey="uid"
                            scroll={{ x: 'max-content' }}
                            // virtual
                            expandable={{
                                expandedRowRender: (p: Package) => {
                                    return (<>{renderVolumesTable(p)}</>)
                                }
                            }}
                            pagination={{
                                showSizeChanger: packages.length > 10,
                                pageSizeOptions: ['10', '20', '30', '40', '50'],
                                defaultPageSize: 20
                            }}
                        />
                    }
                    {loadingServiceAccounts && <LoadingSkeleton />}
                    {errorServiceAccounts && <ShowError error={errorServiceAccounts} />}
                </Card>
                <Drawer
                    title={<><PieChartOutlined /> Volumes of {selectedPackage?.name}</>}
                    placement="bottom"
                    closable={true}
                    onClose={() => { setDrawerOpen(false) }}
                    open={drawerOpen}
                    size="large"
                    destroyOnClose={true}
                >
                    {selectedPackage && renderVolumesTable(selectedPackage)}
                </Drawer>

            </MotionBox>
        </SecureWithLogin>
    )
}
