import { Card, Col, Flex, Row, Tooltip, Typography } from 'antd';

import ClusterStatusTag from './StatusTag';
import { InfoCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const ClusterStatusServiceStatus = (props:any) => {
  const { clusterStatus } = props;

  const statusDisplay: any[] = [
    {
      key: 'Discovery',
      status: clusterStatus?.services?.discovery?.status,
      version: clusterStatus?.services?.discovery?.version,
      lastUpdate: clusterStatus?.services?.discovery?.lastUpdate,
      description: "A view on the cluster status"
    },
    {
      key: 'Screening',
      status: clusterStatus?.services?.screening?.status,
      version: clusterStatus?.services?.screening?.version,
      lastUpdate: clusterStatus?.services?.screening?.lastUpdate,
      description: "The api that is being used for screening"
    },
    { key: 'Updater'  ,
      status: clusterStatus?.services?.updater?.status,
      version: clusterStatus?.services?.updater?.version,
      lastUpdate: clusterStatus?.services?.updater?.lastUpdate,
      description: "The component which is responsible for loading lists from vendors and update Neterium databases"
    },
    { key: 'Utilities',
      status: clusterStatus?.services?.utilities?.status,
      version: clusterStatus?.services?.utilities?.version,
      lastUpdate: clusterStatus?.services?.utilities?.lastUpdate,
      description: "The component which is used to define exceptions, presets but also retrieve information about a matched profile"
    },
  ]

  return (
    <>
    <Flex vertical>
        <Row gutter={[24,24]} justify='center' align='middle'>
            <Col xs={24} lg={18} xxl={12}>
              <Row gutter={[24,24]} justify='center' align='middle'>

                { statusDisplay?.map((e, index) => {
                  return (
                    <Col key={index} xs={24} xl={12}>
                      <Card style={{ width: '100%' }}>
                        <Row gutter={[24,24]}>
                          <Col xs={24}>
                            <Flex justify='space-between' align='center'>
                              <Typography.Title level={4} style={{marginBottom:0}}>{e.key}&nbsp;<Tooltip title={e.description}><InfoCircleOutlined /></Tooltip></Typography.Title><span><ClusterStatusTag status={e.status as string}/></span>
                            </Flex>
                          </Col>
                          <Col xs={24}>
                            <Flex vertical>
                              <div className={`statusBubble ${e.status}`}><span style={{color:'rgba(0,0,0,0.45)',marginBottom:'1rem'}}>Version:</span>{' '}<span>{e.version}</span></div>
                              <div><span style={{color:'rgba(0,0,0,0.45)',marginBottom:'1rem'}}>Last update:</span>{' '}<span>{dayjs(e.lastUpdate).format('YYYY-MM-DD hh:mm:ss A')}</span></div>
                            </Flex>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  );
                })}

              </Row>
            </Col>
        </Row>
    </Flex>
    </>
  )
}

export default ClusterStatusServiceStatus