import { Card, Select, Typography } from 'antd'

import { useNavigate } from 'react-router-dom'

const ReleaseNotesSelector = (props:any) => {
    const navigate = useNavigate();
    const { options, defaultNotesValue } = props

    const handleChange = (value: string) => {
        window.scrollTo(0, 0); // Scroll to the top of the page
        navigate(`/release-notes/${value}`);
    };

    return (
        <Card className="sticky-col">
            <Typography.Title level={4}>Select Release Notes Version</Typography.Title>
            <Select defaultValue={defaultNotesValue} style={{ width: '100%' }} options={options} onChange={handleChange} />
        </Card>
    )
}

export default ReleaseNotesSelector