import '../../assets/scss/Stats.scss';

import { Card, Col, DatePicker, Empty, Flex, Form, Row, Select, Statistic, Tabs, Tooltip, Typography } from 'antd';
import { HomeOutlined, LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { faBarChart, faFilter } from '@fortawesome/free-solid-svg-icons';
import { getIntervalStats, getMaxValue, getTotalValue } from '../../utils/statsUtils';
import { useContext, useEffect, useState } from 'react';

import { Content } from 'antd/es/layout/layout';
import CustomBarChart from './CustomBarChart';
import CustomBarChartStacked from './CustomBarChartStacked';
import CustomLineChart from './CustomLineChart';
import CustomScoreChart from './CustomScoreChart';
import { EnvContext } from '../../contexts/EnvironmentContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '../Layout/Loading';
import LoadingSkeleton from '../Layout/LoadingSkeleton';
import MotionBox from '../Layout/Animated/MotionBox';
import { PageHeader } from '../Layout/header/PageHeader';
import { SecureWithLogin } from '../../utils/secure';
import ShowError from '../Layout/ShowError';
import TopCategories from './TopCategories';
import TopMatches from './TopMatches';
import TotalBox from './TotalBox';
import { callBackEnd } from '../../utils/backEndUtils';
import dayjs from 'dayjs';
import moment from 'moment';
import useAllEnvironments from '../../hooks/useAllEnvironments';
import useElasticStats from '../../hooks/useElasticStats';
import useNamespaceById from '../../hooks/useNamespaceById';
import useStats from '../../hooks/useStats';

const formLayout = {
};

export default function Stats() {
    const env: string = useContext(EnvContext);
    const { allEnv } = useAllEnvironments();
    const [formFilter] = Form.useForm();
    const [fromDate, setFromDate] = useState<string>(moment().subtract(30, 'days').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState<string>(moment().format('YYYY-MM-DD'));
    const [product, setProduct] = useState<string>('jetscan');
    const [client, setClient] = useState<string>('');
    const [project, setProject] = useState<string>('');
    const [pack, setPack] = useState<string>('');
    const [collection, setCollection] = useState<string>('');

    const [currentTab, setCurrentTab] = useState<string>('screening');

    const onTabChange = (key: string) => {
        setCurrentTab(key);
    };

    const {
        stats,
        loading: loadingStats,
        error: errorStats,
        envAttributes,
        currentMonthStats,
        currentYearStats,
        incrementRefreshKey
    } = useStats(env, moment(fromDate).format('YYYY/MM/DD'), moment(toDate).format('YYYY/MM/DD'), client, project, product, pack, collection);

    const {
        topMatches,
        // topSanctions,
        hitrates,
        elapsed,
        topCategories,
        scoreMatch

    } = useElasticStats(env, moment(fromDate).format('YYYY/MM/DD'), moment(toDate).format('YYYY/MM/DD'), client, project, product, pack);

    const [clientList, setClientList] = useState<string[]>([]);
    const [projectList, setProjectList] = useState<string[]>([]);
    const [packagesList, setPackagesList] = useState<Package[]>([]);
    const [collectionsList, setCollectionsList] = useState<Package[]>([]);


    const { getNamespacePackages } = useNamespaceById();

    useEffect(() => {
        async function getRefreshFilterInputs() {
            const packages: any = await getNamespacePackages({ withVolumes: true }).catch((e: any) => console.log(e));
            const filterByEnv: Package[] = packages.filter((p: Package) => p.environment.toUpperCase() === env.toUpperCase());
            const filterByProduct: Package[] = filterByEnv.filter((p: Package) => p.volumes?.some((v: Volume) => v?.product?.toUpperCase() === product.toUpperCase()));
            
            setPackagesList(filterByProduct);

            // const collections: any[] = packages.map((p: Package) => p?.volumes?.map((v: Volume) => v.collection)).flat();
            // setCollectionsList(Array.from((new Set(collections))));

            const clients: any = await callBackEnd(`${env}`, 'GET', `api/v1/core/stats/clients?fromDate=${moment(fromDate).format('YYYY/MM/DD')}&toDate=${moment(toDate).format('YYYY/MM/DD')}`);
            setClientList(clients.data);

            const projects: any = await callBackEnd(`${env}`, 'GET', `api/v1/core/stats/projects?fromDate=${moment(fromDate).format('YYYY/MM/DD')}&toDate=${moment(toDate).format('YYYY/MM/DD')}`);
            setProjectList(projects.data);
        }
        getRefreshFilterInputs().catch((e: any) => { console.log(e) });
    }, [fromDate, toDate, env, getNamespacePackages, formFilter,product])

    useEffect(() => {
        const selectedPackage: Package | null = packagesList.find((p: Package) => p.uid === pack) ?? null;
        console.log("🚀 ~ useEffect ~ selectedPackage:", selectedPackage);
        const filterVolumesByProduct: Volume[] = selectedPackage?.volumes?.filter((v: Volume) => v.product === product?.toUpperCase()) ?? [];

        const collections: any[] = filterVolumesByProduct.map((v: Volume) => v.collection);

        setCollectionsList(Array.from((new Set(collections))));
    }, [pack, packagesList, product])

    useEffect(() => {
        formFilter.setFieldsValue({
            env: env.toUpperCase(),

        });
    }, [env, formFilter])



    return (
        <SecureWithLogin callbackPath="/stats" role={['js_stats']}>
            <Content className='stats'>
                {/* Page Header */}
                <PageHeader
                    withEnvSelector
                    title='Statistics'
                    subtitle='Dashboard'
                    breadcrumbs={[
                        { title: <span><HomeOutlined />&nbsp;Home</span>, href: '/', },
                        { title: <span>Stats</span> }]}
                />

                <Row gutter={[24, 24]}>
                    {/* Top cards */}
                    {(currentMonthStats && currentYearStats && envAttributes !== null) ?
                        <Col xs={24}>
                            <Row gutter={[24, 24]}>
                                {/* <Col xs={24} md={12} xl={8} xxl={6}>
                                    <MotionBox>
                                        <Card type="inner">
                                            <Statistic
                                                title="Maximum authorized volume"
                                                value={(envAttributes[product as keyof typeof envAttributes].volume < 0) ? '∞' : envAttributes[product as keyof typeof envAttributes].volume}
                                                precision={0}
                                                valueStyle={{ color: '#82b9d6' }}
                                                suffix={envAttributes[product as keyof typeof envAttributes].cycle}
                                                groupSeparator=''
                                                decimalSeparator=','
                                            />
                                        </Card>
                                    </MotionBox>
                                </Col> */}
                                <Col xs={24} md={24} xl={8} xxl={8}>
                                    <MotionBox>
                                        <TotalBox
                                            title={`Usage today`}
                                            stats={getIntervalStats(currentMonthStats, 'daily')}
                                            product={product} />
                                    </MotionBox>
                                </Col>
                                <Col xs={24} md={24} xl={8} xxl={8}>
                                    <MotionBox>
                                        <TotalBox
                                            title={`Usage in ${moment().format('MMMM')}`}
                                            stats={getIntervalStats(currentMonthStats, 'monthly')}
                                            product={product} />
                                    </MotionBox>
                                </Col>
                                <Col xs={24} md={24} xl={8} xxl={8}>
                                    <MotionBox>
                                        <TotalBox
                                            title={`Usage in ${moment().format('YYYY')}`}
                                            stats={getIntervalStats(currentYearStats, 'yearly')}
                                            product={product} />
                                    </MotionBox>
                                </Col>

                            </Row>
                        </Col>
                        :
                        <Loading />
                    }

                    {/* Filters Card */}
                    <Col xs={24} xxl={6}>
                        <MotionBox>
                            {/* <Button type="primary" onClick={() => incrementRefreshKey()} >Refresh</Button> */}
                            <Card
                                title={<><FontAwesomeIcon icon={faFilter}></FontAwesomeIcon>{' '}Filters</>}
                                extra={(loadingStats) ? <LoadingOutlined /> : <Tooltip title="Refresh statistics"><ReloadOutlined onClick={() => incrementRefreshKey()} /></Tooltip>}
                            >
                                <Form {...formLayout} labelWrap layout='vertical' form={formFilter}>
                                    <Row gutter={[16, 0]}>
                                        <Col xs={24} >
                                            <Form.Item
                                                name="env"
                                                label="Environment"
                                                rules={[{ required: true }]}
                                                tooltip={`Use the environment selector to change the environment on the top right of the page`}
                                            >
                                                {<Select
                                                    options={allEnv.map((e: { name: string, label: string, color: string }) => {
                                                        return { value: e.name, label: <span>{e.label}</span> }
                                                    }
                                                    )}
                                                    // value={env.toUpperCase()}
                                                    disabled
                                                />}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} >
                                            <Form.Item name="dateInput" label="Dates" rules={[{ required: true }]} initialValue={[dayjs(fromDate), dayjs(toDate)]}>
                                                <DatePicker.RangePicker
                                                    id="dateInput"
                                                    allowClear={false}
                                                    onChange={(date: any, dateString: string[]) => {
                                                        setFromDate(dateString[0])
                                                        setToDate(dateString[1])
                                                    }} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xxl={24}>
                                            <Form.Item name="product" label="Engine" rules={[{ required: true }]} initialValue={product}>
                                                <Select
                                                    options={[
                                                        { value: 'jetscan', label: <span>JETSCAN</span> },
                                                        { value: 'jetflow', label: <span>JETFLOW</span> }
                                                    ]}
                                                    onChange={(v) => setProduct(v)} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xxl={24}>
                                            <Form.Item name="package" label="Package" rules={[{ required: false }]} initialValue={pack}>
                                                <Select
                                                    options={[{ value: '', label: <span>All</span> }, ...packagesList.map((p: Package) => {
                                                        return { value: p.uid, label: <span>{p.name} - {p?.type.replace(/_/g, " ")}</span> }
                                                    })]}
                                                    disabled={(packagesList.length === 0)}
                                                    onChange={(v) => setPack(v)} />
                                                {/* <Cascader
                                                    options={[{ value: '', label: <span>All</span> },...packagesList.filter((p:Package) => p.environment === env).map((p: Package) => {
                                                        return {
                                                            value: p.uid,
                                                            label: <span>{p.name}</span>,
                                                            children: p?.volumes?.map((v: Volume) => {
                                                                return {
                                                                    value: v.uid,
                                                                    label: <span>{v.product} - {v.collection}</span>
                                                                }
                                                            })
                                                        }
                                                    })]}
                                                    disabled={(packagesList.length === 0)}
                                                    onChange={(v:string[]) => {
                                                        const volume: string = v?.at(-1) ?? '';
                                                            setPack(volume);
                                                    }}
                                                    placeholder="Select a package volume"
                                                /> */}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xxl={24}>
                                            <Form.Item name="collection" label="Collection" rules={[{ required: false }]} initialValue={collection}>
                                                <Select
                                                    options={[{ value: '', label: <span>All</span> }, ...collectionsList?.map((c: any) => {
                                                        return { value: c, label: <span>{c}</span> }
                                                    })]}
                                                    disabled={(collectionsList.length === 0)}
                                                    onChange={(v) => setCollection(v)} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xxl={24}>
                                            <Form.Item name="client" label="Client" rules={[{ required: false }]} initialValue={client}>
                                                <Select

                                                    options={[{ value: '', label: <span>All</span> }, ...clientList.map((c: string) => {
                                                        return { value: c, label: <span>{c}</span> }
                                                    })]}
                                                    disabled={(clientList.length === 0)}
                                                    onChange={(v) => setClient(v)} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xxl={24}>
                                            <Form.Item name="project" label="Project" rules={[{ required: false }]} initialValue={project}>
                                                <Select

                                                    options={[{ value: '', label: <span>All</span> }, ...projectList.map((p: string) => {
                                                        return { value: p, label: <span>{p}</span> }
                                                    })]}
                                                    disabled={(projectList.length === 0)}
                                                    onChange={(v) => setProject(v)} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                        </MotionBox>
                    </Col>

                    {(!loadingStats && errorStats === null && envAttributes !== null) ?
                        <Col xs={24} xxl={18}>
                            <Row gutter={[24, 24]}>
                                {/* Tabs Card */}
                                <Col xs={24}>
                                    <MotionBox>
                                        <Card
                                            className="stats-card-nav card"
                                            title={<>
                                                <FontAwesomeIcon icon={faBarChart}></FontAwesomeIcon>{' '}Statistics
                                            </>}
                                            extra={
                                                <Typography.Text type="secondary">
                                                    {product.toUpperCase()} usage between {moment(fromDate).format('MMMM Do YYYY')} & {moment(toDate).format('MMMM Do YYYY')}
                                                    {' '}
                                                    {client !== '' && `for client "${client}"`}
                                                    {client !== '' && project !== '' && ' and '}
                                                    {project !== '' && `for project "${project}"`}
                                                </Typography.Text>
                                            }
                                        >
                                            <Tabs
                                                items={[
                                                    { "key": "screening", label: "Screening usage" },
                                                    { "key": "topMatchProfiles", label: "Top Matched Profiles" },
                                                    { "key": "topCategories", label: "Top Categories" },
                                                    { "key": "hitRates", label: "Daily Hitrates" },
                                                    { "key": "scores", label: "Matching Scores" },
                                                    { "key": "latency", label: "Latency" }
                                                ]}
                                                size="small"
                                                activeKey={currentTab}
                                                onChange={onTabChange}>
                                            </Tabs>
                                        </Card>
                                    </MotionBox>
                                </Col>

                                {/* Stats Cards */}
                                <Col xs={24}>
                                    <MotionBox>
                                        {(stats.length === 0) &&
                                            <Card>
                                                <Empty
                                                    imageStyle={{ height: 60 }}
                                                    description={
                                                        <span>
                                                            No data found in this date range using the selected filters
                                                        </span>
                                                    }
                                                />
                                            </Card>
                                        }
                                        {(stats.length > 0) &&
                                            <Flex vertical>
                                                {currentTab === "screening" &&
                                                    <Row gutter={[24, 24]}>
                                                        <CustomBarChart mode="date" stats={stats} product={product} title='Screening by day' fromDate={fromDate} toDate={toDate} />
                                                        <CustomBarChart mode="collection" stats={stats} product={product} title="Screening by collection" fromDate={fromDate} toDate={toDate} />
                                                        <CustomBarChart mode="explain" stats={stats} product={product} title="Explain usage by day" fromDate={fromDate} toDate={toDate} />
                                                        <Col xs={24} sm={12}>
                                                            <Card>
                                                                <Statistic
                                                                    title="Total in range"
                                                                    value={getTotalValue(stats, product)}
                                                                    precision={0}
                                                                    groupSeparator=''
                                                                    decimalSeparator=','
                                                                    valueStyle={{ color: '#82b9d6' }}
                                                                />
                                                            </Card>
                                                        </Col>
                                                        <Col xs={24} sm={12}>
                                                            <Card>
                                                                <Statistic
                                                                    title="Max Peak in range"
                                                                    value={getMaxValue(stats, product)}
                                                                    groupSeparator=''
                                                                    decimalSeparator=','
                                                                    precision={0}
                                                                    valueStyle={{ color: '#82b9d6' }}
                                                                />
                                                            </Card>
                                                        </Col>
                                                        {/* <Col flex="1 1 300px">
                                        <Card>
                                            <Statistic
                                                title="Average by day of screening in range"
                                                value={getAverageValue(stats, product)}
                                                precision={1}
                                                valueStyle={{ color: '#82b9d6' }}
                                            />
                                        </Card>
                                    </Col> */}
                                                    </Row>
                                                }
                                                {currentTab === "topMatchProfiles" && <Card><TopMatches topMatches={topMatches()} /></Card>}
                                                {currentTab === "topCategories" && <Card><TopCategories topCategories={topCategories()} /></Card>}
                                                {currentTab === "hitRates" && <Card><CustomBarChartStacked stats={hitrates()} product={product} title='Hitrates by day (best effort)' /></Card>}
                                                {currentTab === "scores" && <Card><CustomScoreChart stats={scoreMatch()} product={product} title='Scores of matches (best effort)' /></Card>}
                                                {currentTab === "latency" && <Card><CustomLineChart stats={elapsed()} product={product} title='Latency (Queries/milliseconds)' /></Card>}
                                            </Flex>
                                        }
                                    </MotionBox>
                                </Col>
                            </Row>
                        </Col>
                        :
                        <Col xs={24} xxl={18}>
                            <Row gutter={[24, 24]}>
                                <Col xs={24}>
                                    {loadingStats && <LoadingSkeleton />}
                                    {errorStats && <ShowError error={`There was an error while loading your statistics on ${env.toUpperCase()}`} />}
                                </Col>
                            </Row>
                        </Col>
                    }
                </Row>
            </Content>
        </SecureWithLogin >


    )
}
