import { Avatar, Button, Card, Flex, Form, Input, Space, Tooltip, Typography } from 'antd';
import { InfoCircleOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MotionBox from '../Layout/Animated/MotionBox';
import { callBackEnd } from '../../utils/backEndUtils';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useOidc } from '../../utils/secure';
import { useState } from 'react';

export default function LoginEmail(params: any) {
    const [form] = Form.useForm();
    const { login } = useOidc();
    const redirect = (params.path !== undefined) ? params.path : '/'
    const [isKnownUser, setisKnownUser] = useState<boolean>(localStorage.getItem('userEmail') !== null && localStorage.getItem('userNamespace') !== null);

    const [userEmail, setEmail] = useState<string>(localStorage.getItem('userEmail') ?? '');
    const [isProcessing, setIsProcessing] = useState<boolean>(false);


    const handleLogin = (values: any) => {
        if (values.userEmail !== undefined && values.userEmail !== null) {
            localStorage.setItem('userEmail', values.userEmail);
            setEmail(values.userEmail);
            
            async function getIdp() {
                setIsProcessing(true);
                let response: any = await callBackEnd('portal', 'POST', `hint`, JSON.stringify({ email: values.userEmail })).catch((e: any) => {
                    console.log(e)
                });
                const idpNamespace: string | null = response.hint;
                setIsProcessing(false);
                if (idpNamespace !== null) {
                    localStorage.setItem('userNamespace', idpNamespace);
                    login(redirect, { "kc_idp_hint": idpNamespace, "login_hint": values.userEmail })
                }
                else {
                    login(redirect, { "kc_idp_hint": "", "login_hint": values.userEmail })
                }
            }
            getIdp();
        }
    }

    const handleNotMe = () => {
        localStorage.removeItem('userEmail');
        localStorage.removeItem('userNamespace');
        setEmail('');
        setisKnownUser(false);
        form.setFieldsValue({ userEmail: '' });
    }

    return (
        <MotionBox>
            <Card
                title="Your Neterium Dashboard"
                className="sectionCard"
            >
                <Flex vertical gap={'middle'}>
                    <Form
                        labelWrap
                        onFinish={handleLogin}
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 16 }}
                        form={form}
                    // layout="inline"
                    >
                        <Form.Item
                            wrapperCol={{ offset: 4, span: 16 }}
                            hidden={!isKnownUser}
                        >
                            <Flex gap={'small'} align='center'>
                                <Avatar size="large" style={{ backgroundColor: '#82b9d6' }} icon={<UserOutlined />} />
                                <p>
                                    <Typography.Text italic>Previously logged in as</Typography.Text><br />
                                    {userEmail}
                                </p>
                            </Flex>
                        </Form.Item>
                        <Form.Item
                            label="Enter your corporate e-mail"
                            name="userEmail"
                            rules={[{ type: 'email', required: true, message: 'Please input your e-mail!' }]}
                            initialValue={userEmail}
                            hidden={isKnownUser}
                        >
                            <Input
                                prefix={<MailOutlined />}
                                suffix={
                                    <Tooltip title="Your corporate e-mail">
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                                placeholder='E-mail'
                            />
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                            <Space size="small">
                                {isKnownUser &&
                                    <Tooltip title="Login/Register with a different account">
                                        <Button type="dashed" onClick={handleNotMe}>No it's not me</Button>
                                    </Tooltip>
                                }
                                <Button type="primary" htmlType="submit" loading={isProcessing} icon={<FontAwesomeIcon icon={faArrowRight} />}>
                                    Next
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Flex>

            </Card>
        </MotionBox>

    )
}
