import '../../../../assets/scss/ServiceAccountsList.scss';

import { Button, Card, Checkbox, Col, Empty, Flex, Input, List, Popover, Row, Space, Typography } from 'antd';
import { faCog, faMagnifyingGlass, faSliders } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { SecureWithLogin } from '../../../../utils/secure';
import ServiceAccountsTable from './serviceAccountComponents/ServiceAccountsTable';
import useNamespaceById from '../../../../hooks/useNamespaceById';

export default function ServiceAccountsList(props: any) {

    const [selectedStatuses, setSelectedStatuses] = useState<string[]>(["ACTIVE","INACTIVE"]); // Active, Inactive, Archived
    const [search, setSearch] = useState<string>('');
    const { namespaceId } = props;

    const [loadingServiceAccounts, setLoadingServiceAccounts] = useState<boolean>(true);
    const [errorServiceAccounts, setErrorServiceAccounts] = useState<any>(null);
    const [serviceAccounts, setServiceAccounts] = useState<ServiceAccount[] | null>(null);
    const [filteredServiceAccounts, setFilteredServiceAccounts] = useState<ServiceAccount[] | null | undefined>(null);

    const { getNamespacePackages,getNamespaceServiceAccounts } = useNamespaceById();

    const getServiceAccounts = useCallback(async () => {
        try {
            setLoadingServiceAccounts(true);
            const response: any = await getNamespaceServiceAccounts().catch((e: any) => console.log(e));
            setServiceAccounts(response);


        } catch (error: any) {
            setErrorServiceAccounts(error);
        } finally {
            setLoadingServiceAccounts(false);
        }
    }, [getNamespaceServiceAccounts]);

    useEffect(() => {
        if (namespaceId !== "") {
            getServiceAccounts().catch((error) => { console.log(error) });
        }
    }, [namespaceId, getServiceAccounts, getNamespacePackages]);

    useEffect(() => {
        
        const serviceAccountsFiltered= serviceAccounts && serviceAccounts.filter(sa=> selectedStatuses.includes(sa.status));

        const serviceAccountSearched = serviceAccountsFiltered?.filter((sa: any) => {
            if (search === '') return sa;
            else if (sa?.name.toLowerCase().includes(search.toLowerCase())) return sa;
            else return null;
        })

        // Order Service Accounts by status
        const SAOrder = { ACTIVE: 1, INACTIVE: 2, ARCHIVED: 3 };
        const serviceAccountOrdered = serviceAccountSearched && [...serviceAccountSearched].sort((a, b) => SAOrder[a.status] - SAOrder[b.status]);
        
        setFilteredServiceAccounts(serviceAccountOrdered);

    }, [search, serviceAccounts, selectedStatuses]);

    const handleStatusToggle = (status: string) => {
        if (selectedStatuses.includes(status)) {
            // Remove status from selectedStatuses
            setSelectedStatuses(selectedStatuses.filter(s => s !== status));
          } else {
            // Add status to selectedStatuses
            setSelectedStatuses([...selectedStatuses, status]);
            // setFilteredServiceAccounts(prevState => prevState?.filter((sa: any) => sa.environment === env && sa.status === status && sa.hasOwnProperty('authenticationType')));
            
          }
    }

    const popoverItems = [
        {
            key: '1',
            label: (
                <Checkbox checked={selectedStatuses.includes("ACTIVE")} onChange={() => handleStatusToggle('ACTIVE')}>Active</Checkbox>
          )
        },
        {
          key: '2',
          label: (
                <Checkbox checked={selectedStatuses.includes("INACTIVE")} onChange={() => handleStatusToggle('INACTIVE')}>Inactive</Checkbox>
          ),
        },
        {
          key: '3',
            label: (
                <Checkbox checked={selectedStatuses.includes("ARCHIVED")} onChange={() => handleStatusToggle('ARCHIVED')} >Archived</Checkbox>
            ),
          },
    ];

    const content = <List dataSource={popoverItems} renderItem={(item, index) => item && <List.Item key={index} style={{border: 'none', padding:'6px 12px'}}>{item.label}</List.Item>} />;

    // final render of the component
    return (
        <SecureWithLogin callbackPath="/nsadmin/service-accounts" role={['nsadmin_service_accounts']}>
            <Card title={<><FontAwesomeIcon icon={faCog}></FontAwesomeIcon>{' '} My Service Account</>} className="sectionCard">
                <Row gutter={[24, 24]}>
                <Col xs={24} className='saList'>
                        <Flex justify='space-between' >
                            <Space>
                                <Input type="text" placeholder="Search" className="search" name="search" prefix={<FontAwesomeIcon icon={faMagnifyingGlass} />} aria-placeholder='Search' onChange={(e: any) => setSearch(e.target.value)}/>
                                <Popover content={ content } placement="bottom" trigger={['click']}>
                                    <Button ><FontAwesomeIcon icon={faSliders} /></Button>
                                </Popover>
                            </Space>
                            <div>
                                <Link to={`/nsadmin/${namespaceId}/service-accounts/create`}><Button type="primary">Create</Button></Link>
                            </div>
                        </Flex>
                </Col>
                    {(serviceAccounts !== null && serviceAccounts.length > 0 && filteredServiceAccounts !== undefined && filteredServiceAccounts !== null && filteredServiceAccounts.length > 0 && errorServiceAccounts === null && !loadingServiceAccounts ) ?

                        <Col xs={24}><div><ServiceAccountsTable serviceAccountSource={filteredServiceAccounts} namespaceId={namespaceId} getServiceAccounts={getServiceAccounts}/></div></Col>
                        :
                        <Col xs={24}><Empty description={ <Typography.Text>No Service Account</Typography.Text> }/></Col>

                    }

                </Row>
            </Card>
        </SecureWithLogin>
    )
}