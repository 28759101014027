import { Card, Col, DescriptionsProps, Row, Typography } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HomeFilled } from '@ant-design/icons';
import Loading from './Loading';
import MotionBox from './Animated/MotionBox';
import { Outlet } from 'react-router-dom';
import { PageHeader } from './header/PageHeader';
import { ShowIfAccess } from '../../utils/secure';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import useNamespaceById from '../../hooks/useNamespaceById';
import { useState } from 'react';

export default function NSALayout(props: any) {
    const [headerTitle, setHeaderTitle] = useState("");
    const [usersCount, setUsersCount] = useState<number>(0);
    const [packagesCount, setPackagesCount] = useState<number>(0);
    // const [ownerName, setOwnerName] = useState<string>('');

    // const TAB_ITEMS: TabsProps['items'] = NSADMIN_ITEMS.map((u:any) => ({
    //     key: u.title,
    //     label: u.title,
    //   }));


    // const { namespace, loading, error, getNamespaceUsersCount, getNamespacePackagesCount, getNamespaceOwnerName } = useNamespaceById();
    const { namespace, loading, error, getNamespaceUsersCount, getNamespacePackagesCount } = useNamespaceById();

    const fetchData = async () => {
        try {
            const usersCount: number = await getNamespaceUsersCount();
            const packagesCount: number = await getNamespacePackagesCount();
            // const ownerName: string = await getNamespaceOwnerName();

            setUsersCount(usersCount);
            setPackagesCount(packagesCount);
            // setOwnerName(ownerName);

        } catch (error) {
            console.error(error);
        }
    };
    fetchData();

    const items: DescriptionsProps['items'] = [
        {
            key: '1',
            label: 'Number of users',
            children: <span>{' '}{usersCount}</span>,
        },
        {
            key: '2',
            label: 'Number of packages',
            children: <span>{' '}{packagesCount}</span>,
        },
        {
            key: '3',
            label: 'Namespace email Domain',
            children: <span>{' '}@{namespace?.emailDomain}</span>,
        },
        // {
        //   key: '4',
        //   label: 'Namespace Owner',
        //   children: <span>{' '}{ownerName}</span>,
        // }
    ];


    return (
        <ShowIfAccess authenticated={true} role={['nsadmin_home']}>
            <div className="nsadmin">
                <MotionBox>
                    <PageHeader
                        icon={<HomeFilled />}
                        withEnvSelector
                        title={headerTitle}
                        subtitle='Namespace Management'
                    />
                    {/* <Row gutter={[24,24]}>
                                {(users !== 0) && <Col style={{display:'flex', flexDirection:'column'}} xs={24} lg={12} xxl={6}><Card style={{flex:'1'}} ><Typography.Title level={5}>Number of users:</Typography.Title><Typography.Text>{users}</Typography.Text></Card></Col>}
                                {(!errorPackages && !loadingPackages && packages !== null) && <Col style={{display:'flex', flexDirection:'column'}} xs={24} lg={12} xxl={6}><Card style={{flex:'1'}}><Typography.Title level={5}>Number of user Packages:</Typography.Title><Typography.Text>{packages.length}</Typography.Text></Card></Col>}
                                <Col style={{display:'flex', flexDirection:'column'}} xs={24} lg={12} xxl={6}><Card style={{flex:'1'}}><Typography.Title level={5}>Namespace Email Domain:</Typography.Title><Typography.Text>{namespace.emailDomain}</Typography.Text></Card></Col>
                                <Col style={{display:'flex', flexDirection:'column'}} xs={24} lg={12} xxl={6}><Card style={{flex:'1'}}><ShowOwner namespaceId={namespace.uid} /></Card></Col>
                                </Row> */}
                    <Row gutter={[24, 24]}>
                        <Col xs={24}>
                            {/* <Card actions={[<Tabs defaultActiveKey="1" items={TAB_ITEMS}  />]}> */}
                            {!loading && error === null ?
                                <Card>
                                    <Row gutter={[24, 24]}>
                                        <Col xs={24}><Typography.Title level={3}><FontAwesomeIcon icon={faBuilding} />&nbsp;Namespace details</Typography.Title></Col>
                                        {items.map((item: any) => (
                                            <Col key={item.key} xs={24} md={12} xxl={8}><span style={{ color: 'rgba(0,0,0,0.45)', marginBottom: '1rem' }}>{item.label}:</span>{item.children}</Col>
                                        ))}
                                    </Row>
                                </Card>
                                :
                                <Loading />
                            }
                        </Col>
                        <Col xs={24}>
                            <Outlet context={{ setHeaderTitle }} />
                        </Col>
                    </Row>
                </MotionBox>
            </div>
        </ShowIfAccess>
    );
}